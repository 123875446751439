const REGISTER_AS_ORG = "REGISTER AS AN ORGANIZATION";
const REGISTER_AS_IVD = "REGISTER AS AN INDIVIDUAL";
const REGISTER_AS_COPYRIGHTS_HODLER = "Register as a Copyright Holder";
const ACCOUNT_REGISTRATION = "Account Registration";
const ACCOUNT_TYPE = "Account Type";
const INDIVIDUAL = "Individual";
const COMPANY = "Organization";
const VERIFY_EMAIL = "Verify Your email";
const CONTINUE = "Continue";
const STATUS = "Status";
const MESSAGE = "Message";
const DATE = "Date";
const CREATE_NEW_ACCOUNT = "Create New Account";
const FILE_FORMAT_BOOK_MESSAGE =
  "The file formats you can upload are .epub,.mobi and .pdf. You cannot upload two files with the same format. ";
const FILE_FORMAT_COVER_MESSAGE =
  "The file formats you can upload are .png, .jpgs.";
const FILE_FORMAT_BOOK = ".pdf,.epub,.mobi";
const FILE_FORMAT_COVER = "image/png, image/jpg, image/jpeg, image/tiff";
const FILE_UPLOAD_MESSAGE_BOOK = "Upload Book";
const FILE_UPLOAD_MESSAGE_MAIN_URL = "Upload Main Image";
const FILE_UPLOAD_MESSAGE_COVER = "Upload Cover";
const DISCARD_POP_UP_TITLE = "Discard Changes";
const UPLOAD_POP_UP_TITLE = "Ebook File";
const BOOK_EXISTS_POP_UP_TITLE = "Ebook Already Exists";
const BOOK_EXISTS_POP_UP_MESSAGE = "It seems Harry Potter already exists.";
const EBOOK_EXISTS_IN_CLAIM =
  "It seems Harry Potter already exists in your claims.";
const EBOOK_EXISTS_IN_OTHER_USER_CLAIM =
  "It seems Harry Potter already exists in other user's claim list. We can not proceed further.";
const VA_EXISTS_IN_CLAIM = "It seems Bubbles already exists.";
const VA_EXISTS_IN_OTHER_USER_CLAIM =
  "It seems Bubbles already exists in other user's claim list. We can not proceed further.";
const DISCARD_POP_UP_DESCRIP =
  "You are about to discard the changes made to this claim. Are you sure you want to discard the changes?";
const ADMIN_CHOOSE_CLAIM_REGISTRANT = "Choose Claim Registrant";
const ADMIN_CHOOSE_CLAIM_REGISTRANT_DESC =
  "Choose the claim registrant you would like to submit claims for. Only approved claim registrants are displayed.";
const SUBMITTED = "Submitted";
const APPROVED = "Approved";
const VERIFIED = "Verified";
const PENDING = "Pending";
const DEACTIVATED = "Deactivated";
const REJECTED = "Rejected";
const ADMIN: string = "admin";
const USER: string = "user";
const APPROVE = "Approve";
const REJECT = "Reject";
const VIEW = "View";
const UPDATE_INFO = "Update Info";
const UPDATE_CLAIM_STATUS = "Update Claim Status";
const ADD_EBOOK_FOR_REGISTRANT_PREFIX = "Add Ebook For";
const USER_MANAGEMENT_HEADING = "User Management";
const ALL = "all";
const VA_IMAGE_FORMAT = ".png, .jpeg, .jpg, .tiff";
const ORG_INFO = "Organization Information";
const CONTACT_PERSON_INFO = "Contact person Information";
const VERIFICATION_OF_ORG_STATUS = "Verification of organization status";
const TRUSTED_SOURCE = "Trusted Source";
const NON_TRUSTED_SOURCE = "Non-Trusted Source";
const ADD_VA_FOR_REGISTRANT_PREFIX = "Add Visual Art For";
const EBOOK_CLAIM_TITLE = "Ebook Claims";
const ADD_EBOOK_CLAIM_BTN = "Add Ebook";
const VA_CLAIM_TITLE = "Visual Art Claims";
const EBOOKS = "Ebooks";
const VISUAL_ARTS = "Visual Arts";
const ADD_VA_CLAIM_BTN = "Add Visual Arts";
const DATE_FORMAT = "MMMM DD, YYYY";
const DATE_FORMAT_CLAIM_DETAILS = "YYYY/MM/DD";
const DATE_FORMAT_AUDIT_HISTORY = "LLL";
const TERMS_LAST_UPDATED = "October 4, 2019";
const PRIVACY_POLICY_LAST_UPDATED = "October 4, 2019";
const NO_WORK_IN_CATALOG = "There is no work in the catalogue";
const IS_PRIMARY_CONTACT =
  "This person is authorized to act on behalf of the organization.";
const CONFLICT_ERROR = 409;
const NOT_FOUND_ERROR_STATUSCODE = 404;
const BASE_URL = "http://localhost:3000";
const ROWS_PER_PAGE = 10;
const ROWS_PER_PAGE_LANDING = 21;
const TRUSTED = "Trusted";
const NON_TRUSTED = "Nontrusted";
const CONFIRM = "Confirm";
const CANCEL = "Cancel";
const NO_USER = "No User";
const DOC_REQUIRED = "Document Required";
const NO_RESULTS = "No results found.";
const USER_ID = "userId";
const TYPE = "type";
const EMAIL_VERIFIED = "emailVerified";
const EMAIL = "email";
const ROLE = "role";
const SEARCH_VALIDATION_ERROR_MESSAGE =
  "Please enter a search criteria to begin search";
const EDIT_CLAIM_CONFIRM_DIALOG =
  "Updating a verified claim will revert the status to “Pending”. Are you sure you want to continue?";
const EDIT_PROFILE_CONFIRM_DIALOG =
  "Updating a verified profile will revert the status to “Pending”. Are you sure you want to continue?";
const EDIT_CLAIM_CONFIRM_DIALOG_HEADER = "Edit Verified Claim";
const EDIT_PROFILE_HEADER = "Edit Profile";
const LANDING_PAGE_VIDEO = "https://www.youtube.com/embed/UQR0cIRyOdw";
const ACCESS_CODE_HEADER = "Enter your Access Code";
const DEFAULT_DATE_FORMAT = "YYYY/MM/DD";
const SUPER_OWNER_EMAIL = "info@prescientinnovations.com";
const ACTIVE = "Active";
const PROMOTE = "Promote";
const DEACTIVATE = "Deactivate";
const GO_BACK_TO_CLAIM_LIST = "Go Back to Claim List";
const CONTACT_US = "Contact us for any queries.";
const MAX_BOOK_UPLOAD_COUNT = 3;
const NO_ISCC_GENERATED = "No ISCC generated for this claim";
const NO_ASSET_FOUND = "No assets found for this claim";

export {
  LANDING_PAGE_VIDEO,
  VA_IMAGE_FORMAT,
  BASE_URL,
  PRIVACY_POLICY_LAST_UPDATED,
  TERMS_LAST_UPDATED,
  REGISTER_AS_ORG,
  REGISTER_AS_IVD,
  REGISTER_AS_COPYRIGHTS_HODLER,
  ACCOUNT_REGISTRATION,
  ACCOUNT_TYPE,
  INDIVIDUAL,
  COMPANY,
  VERIFY_EMAIL,
  CONTINUE,
  STATUS,
  MESSAGE,
  DATE,
  CREATE_NEW_ACCOUNT,
  FILE_FORMAT_BOOK,
  FILE_FORMAT_COVER,
  FILE_UPLOAD_MESSAGE_MAIN_URL,
  FILE_FORMAT_COVER_MESSAGE,
  FILE_FORMAT_BOOK_MESSAGE,
  FILE_UPLOAD_MESSAGE_BOOK,
  FILE_UPLOAD_MESSAGE_COVER,
  DISCARD_POP_UP_TITLE,
  UPLOAD_POP_UP_TITLE,
  DISCARD_POP_UP_DESCRIP,
  BOOK_EXISTS_POP_UP_TITLE,
  BOOK_EXISTS_POP_UP_MESSAGE,
  EBOOK_EXISTS_IN_CLAIM,
  EBOOK_EXISTS_IN_OTHER_USER_CLAIM,
  VA_EXISTS_IN_CLAIM,
  VA_EXISTS_IN_OTHER_USER_CLAIM,
  SUBMITTED,
  APPROVED,
  PENDING,
  DEACTIVATED,
  REJECTED,
  ADMIN,
  USER,
  APPROVE,
  REJECT,
  UPDATE_CLAIM_STATUS,
  VIEW,
  ADMIN_CHOOSE_CLAIM_REGISTRANT,
  ADMIN_CHOOSE_CLAIM_REGISTRANT_DESC,
  ADD_EBOOK_FOR_REGISTRANT_PREFIX,
  ADD_VA_FOR_REGISTRANT_PREFIX,
  USER_MANAGEMENT_HEADING,
  ALL,
  UPDATE_INFO,
  ORG_INFO,
  CONTACT_PERSON_INFO,
  VERIFICATION_OF_ORG_STATUS,
  TRUSTED_SOURCE,
  NON_TRUSTED_SOURCE,
  EBOOK_CLAIM_TITLE,
  ADD_EBOOK_CLAIM_BTN,
  VA_CLAIM_TITLE,
  ADD_VA_CLAIM_BTN,
  DATE_FORMAT,
  DATE_FORMAT_CLAIM_DETAILS,
  NO_WORK_IN_CATALOG,
  IS_PRIMARY_CONTACT,
  CONFLICT_ERROR,
  ROWS_PER_PAGE,
  TRUSTED,
  NON_TRUSTED,
  CONFIRM,
  CANCEL,
  NO_RESULTS,
  VERIFIED,
  NO_USER,
  EBOOKS,
  VISUAL_ARTS,
  EMAIL_VERIFIED,
  EMAIL,
  ROLE,
  USER_ID,
  TYPE,
  DOC_REQUIRED,
  ROWS_PER_PAGE_LANDING,
  SEARCH_VALIDATION_ERROR_MESSAGE,
  EDIT_CLAIM_CONFIRM_DIALOG,
  EDIT_CLAIM_CONFIRM_DIALOG_HEADER,
  ACCESS_CODE_HEADER,
  DATE_FORMAT_AUDIT_HISTORY,
  DEFAULT_DATE_FORMAT,
  NOT_FOUND_ERROR_STATUSCODE,
  SUPER_OWNER_EMAIL,
  ACTIVE,
  PROMOTE,
  DEACTIVATE,
  GO_BACK_TO_CLAIM_LIST,
  CONTACT_US,
  MAX_BOOK_UPLOAD_COUNT,
  NO_ISCC_GENERATED,
  NO_ASSET_FOUND,
  EDIT_PROFILE_HEADER,
  EDIT_PROFILE_CONFIRM_DIALOG,
};
