import { Action, ActionType } from "../models/actiontype";
import { ArtForm } from "../models/ArtForm";

import createReducer from "./createReducer";

export const artForms = createReducer<ArtForm[]>([], {
  [ActionType.ARTFORMS_ADD](state: ArtForm[], action: Action<ArtForm[]>) {
    return action.payload;
  },
});
