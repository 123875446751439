import { Action, ActionType } from "../models/actiontype";
import { UserManagement } from "../models/UserManagement";

import createReducer from "./createReducer";

export const users = createReducer<UserManagement[]>([], {
  [ActionType.SET_USER_DATA](
    state: UserManagement[],
    action: Action<UserManagement>
  ) {
    return [action.payload];
  },
  [ActionType.USER_DATA_RESET](state: [], action: Action<UserManagement>) {
    return [...state.splice(state.length)];
  },
});
