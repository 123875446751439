export const createDefaultAddressInformation = () => {
  return {
    type: "",
    address: "",
    city: "",
    province: "",
    country: "",
    postCode: "",
  };
};
