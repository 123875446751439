// These vars are replaced by the configsettings.json fetch in index.tsx
// Create a configsettings.json in your public dir if using yarn start
export default {
  SERVER_HOST: "",
  AUTH0_DOMAIN: "",
  AUTH0_CLIENT_ID: "",
  AUTH0_AUDIENCE: "",
  PLAID_PUBLIC_KEY: "",
  CREATOR_ACCESS_CODE_HASH: "",
  ADMIN_ACCESS_CODE_HASH: "",
  SMARTLOOK_KEY: "",
  ETHEREUM_BLOCKCHAIN_EXPLORER_PREFIX: "",
  NODE_ENV: "dev",
};
