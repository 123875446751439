export interface ContactInformation {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
  isAuthorized: boolean;
}

export const createDefaultContactInformation = () => {
  return {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    jobTitle: "",
    isAuthorized: false,
  };
};
