import createReducer from "./createReducer";
import { Action, ActionType } from "models/actiontype";
import {
  ClaimRegistrantsData,
  createClaimRegistrant,
} from "models/ClaimRegistrantsDetails";

const initialState = createClaimRegistrant("", "", "", "", "", "", "", "", "");

export const claimRegistrant = createReducer<ClaimRegistrantsData>(
  initialState,
  {
    [ActionType.SET_CLAIM_REGISTRANT](
      state: ClaimRegistrantsData,
      action: Action<ClaimRegistrantsData>
    ) {
      return action.payload;
    },

    [ActionType.CLAIM_REGISTRANT_RESET](
      state: [],
      action: Action<ClaimRegistrantsData>
    ) {
      return [...state.splice(state.length)];
    },
  }
);
