import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const conatinerFluid = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  width: "100%"
};
const container = {
  ...conatinerFluid,
  "@media (min-width: 576px)": {
    maxWidth: "540px"
  },
  "@media (min-width: 768px)": {
    maxWidth: "720px"
  },
  "@media (min-width: 992px)": {
    maxWidth: "960px"
  },
  "@media (min-width: 1200px)": {
    maxWidth: "1140px"
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      paddingTop: 23,
      margin: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),

      width: "22px",
      height: "21px",
      objectFit: "contain",

      color: "#252066",
      paddingRight: "20px",
      paddingTop: "24.4px"
    },
    appBar: {
      display: "flex",
      border: "0",
      borderRadius: "3px",
      padding: "0.625rem 0",
      marginBottom: "20px",
      color: "#555",
      backgroundColor: "#fff",
      boxShadow:
        "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
      transition: "all 150ms ease 0s",
      alignItems: "center",
      flexFlow: "row nowrap",
      justifyContent: "flex-start",
      position: "relative",
      height: "10%",
      flexDirection: "row",
      margin: "0 35px",
      paddingLeft: "10px",
      paddingTop: "10px",
      paddingRight: "10px",
    },
    appBarMobile: {
      display: "flex",
      border: "0",
      borderRadius: "3px",
      color: "#555",
      backgroundColor: "#fff",
      boxShadow:
        "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
      transition: "all 150ms ease 0s",
      alignItems: "center",
      flexFlow: "row nowrap",
      justifyContent: "flex-start",
      position: "relative",
      width: "auto",
      height: "10%",
      flexDirection: "row",
      margin: "0 10px",
      padding: "5px 10px",
    },
    container: {
      ...container,
      minHeight: "50px",
      flex: "1",
      alignItems: "center",
      justifyContent: "space-between",
      display: "flex",
      flexWrap: "nowrap"
    },
    menuButton: {
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },
    appResponsive: {
      margin: "20px 10px"
    },
    drawerPaper: {
      width: "100%",
      objectFit: "contain",
      boxShadow: "0 11px 12px 0 rgba(0, 0, 0, 0.23)",
      backgroundColor: "#ffffff"
    },
    paper: {
      width: "90%",
      display: "flex",
      flexDirection: "row",
      margin: "auto"
    },
    image: {
      width: 225,
      height: 63,
      cursor: "pointer"
    },
    imageMobile: {
      width: "118px",
      height: "51px",
      objectFit: "contain",
      paddingTop: "6px"
    },

    navHeaders: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      width: "100%"
    },

    text: {
      font: "OpenSans",
      fontSize: 14,
      fontWeight: 600,
      color: "#252066",
      cursor: "pointer",
      letterSpacing: "0.88px",
      textDecoration: "none",
    },
    textMobile: {
      fontSize: 17,
      fontWeight: 600,
      color: "#252066",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "2.35",
      letterSpacing: "0.84px",
      textAlign: "right",
      paddingRight: "12px"
    },
    iconButton: {
      padding: 10
    },
    button: {
      marginTop: theme.spacing(3),
      width: 302,
      height: 48
    }
  })
);

export default useStyles;
