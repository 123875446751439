import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import LandingNavHeaderCss from "../../../styles/LandingPage/LandingPageNavHeaderCss";
import logo from "assets/landing-logo.png";
import { RootState } from "reducers";
import { hasAdminRole, isClaimant } from "utils/utility";

interface Props extends RouteComponentProps<void> {}

function LandingPageNavHeader(this: any, props: Props) {
  const { history } = props;
  const classes = LandingNavHeaderCss();
  const { loginWithRedirect, logout } = useAuth0();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const authState = useSelector((state: RootState) => state.auth);

  const handleClickOpen = () => {
    setMobileOpen(true);
  };

  const handleClose = () => {
    setMobileOpen(false);
  };

  function returnToHome() {
    history.push({ pathname: "/" });
  }

  function handleLogOut() {
    sessionStorage.clear();
    logout({ returnTo: window.location.origin });
  }

  return (
    <Box
      className={
        window.innerWidth <= 500 ? classes.appBarMobile : classes.appBar
      }
    >
      <img
        className={classes.image}
        onClick={() => {
          returnToHome();
        }}
        src={logo}
        alt="logo"
      />
      <Box className={classes.navHeaders}>
        <Hidden xsDown>
          <Typography
            onClick={() => {
              returnToHome();
            }}
            className={classes.text}
          >
            HOME
          </Typography>
        </Hidden>
        <Hidden xsDown>
          <a className={classes.text} href="/#whyItMatter">
            WHY IT MATTERS
          </a>
        </Hidden>
        <Hidden xsDown>
          <a className={classes.text} href="/#aboutUs">
            ABOUT US
          </a>
        </Hidden>
        <Hidden xsDown>
          <Typography className={classes.text}>
            <Link
              to="/faq"
              style={{ textDecoration: "none", color: "#252066" }}
            >
              FAQs
            </Link>
          </Typography>
        </Hidden>
        {authState && authState.role === "" && (
          <div>
            <Hidden xsDown>
              <Button
                className={classes.text}
                onClick={() => loginWithRedirect({})}
              >
                LOG IN
              </Button>{" "}
              |
              <Button
                className={classes.text}
                onClick={() => loginWithRedirect({})}
              >
                SIGN UP
              </Button>
            </Hidden>
          </div>
        )}
        {hasAdminRole(authState.role) && (
          <div>
            <Hidden xsDown>
              <Button
                className={classes.text}
                onClick={() => history.push({ pathname: "/admin/dashboard" })}
              >
                MY ACCOUNT
              </Button>
              |
              <Button className={classes.text} onClick={() => handleLogOut()}>
                LOG OUT
              </Button>
            </Hidden>
          </div>
        )}
        {isClaimant(authState.role) && (
          <div>
            <Hidden xsDown>
              <Button
                className={classes.text}
                onClick={() => history.push({ pathname: "/user/dashboard" })}
              >
                MY ACCOUNT
              </Button>
              |
              <Button className={classes.text} onClick={() => handleLogOut()}>
                LOG OUT
              </Button>
            </Hidden>
          </div>
        )}
      </Box>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleClickOpen}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor={"top"}
          open={mobileOpen}
          classes={{ paper: classes.drawerPaper }}
          onClose={handleClose}
        >
          <div className={classes.appResponsive}>
            <img className={classes.imageMobile} src={logo} alt="logo" />
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              onClick={() => {
                returnToHome();
              }}
              className={classes.textMobile}
            >
              HOME
            </Typography>
            <Typography className={classes.textMobile}>
              <Link
                to="/faq"
                style={{ textDecoration: "none", color: "#252066" }}
              >
                FAQS{" "}
              </Link>{" "}
            </Typography>
          </div>
        </Drawer>
      </Hidden>
    </Box>
  );
}
export default withRouter(LandingPageNavHeader);
