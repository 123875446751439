export interface ClaimRegistrantsData {
  id: string;
  name: string;
  type: string;
  email: string;
  phoneNumber: string;
  parentCompany: string;
  subsidiaryCompany: string;
  websiteUrl: string;
  address: string;
}

export function createClaimRegistrant(
  id: string,
  name: string,
  type: string,
  email: string,
  phoneNumber: string,
  parentCompany: string,
  subsidiaryCompany: string,
  websiteUrl: string,
  address: string
): ClaimRegistrantsData {
  return {
    id,
    name,
    type,
    email,
    phoneNumber,
    parentCompany,
    subsidiaryCompany,
    websiteUrl,
    address,
  };
}
