import { Action, ActionType } from "../models/actiontype";
import { Currency } from "../models/Currency";

import createReducer from "./createReducer";

export const currencies = createReducer<Currency[]>([], {
  [ActionType.CURRENCIES_ADD](state: Currency[], action: Action<Currency[]>) {
    return action.payload;
  },
});
