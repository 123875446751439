import { Action, ActionType } from "../models/actiontype";
import { Subject } from "../models/Subject";

import createReducer from "./createReducer";

export const subjects = createReducer<Subject[]>([], {
  [ActionType.SUBJECTS_ADD](state: Subject[], action: Action<Subject[]>) {
    return action.payload;
  },
});
