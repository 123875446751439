import baseService from "./baseService";
import config from "../config";

export default class UserService {
  async getUser(
    userId: string,
    getAccessToken: (arg0: object) => Promise<string>
  ) {
    const accessToken = await getAccessToken({
      audience: config.AUTH0_AUDIENCE,
      scope: "read:current_user",
    });

    const apiEndpoint = userId ? `/users/${userId}` : `/users/`;

    return baseService.get(apiEndpoint, accessToken);
  }

  async getAllUsers(
    queryParam: any,
    getAccessToken: (arg0: object) => Promise<string>
  ) {
    const accessToken = await getAccessToken({
      audience: config.AUTH0_AUDIENCE,
      scope: "read:current_user",
    });

    const apiEndpoint = `/users/all?${queryParam}`;
    return baseService.get(apiEndpoint, accessToken);
  }

  async getAllUsersCount(getAccessToken: (arg0: object) => Promise<string>) {
    const accessToken = await getAccessToken({
      audience: config.AUTH0_AUDIENCE,
      scope: "read:current_user",
    });

    const apiEndpoint = `/users/totals`;
    return baseService.get(apiEndpoint, accessToken);
  }

  async updateUser(
    data: any,
    getAccessToken: (arg0: object) => Promise<string>
  ) {
    const accessToken = await getAccessToken({
      audience: config.AUTH0_AUDIENCE,
      scope: "read:current_user",
    });

    return baseService.put(`/users/`, data, accessToken);
  }

  async checkEmailVerification(
    getAccessToken: (arg0: object) => Promise<string>
  ) {
    const accessToken = await getAccessToken({
      audience: config.AUTH0_AUDIENCE,
      scope: "read:current_user",
    });

    const apiEndpoint = `/users/verify-email`;
    return baseService.get(apiEndpoint, accessToken);
  }

  async resendVerifyEmail(getAccessToken: (arg0: object) => Promise<string>) {
    const accessToken = await getAccessToken({
      audience: config.AUTH0_AUDIENCE,
      scope: "read:current_user",
    });

    const apiEndpoint = `/users/verify-email`;
    return baseService.post(apiEndpoint, {}, accessToken);
  }

  async updateUserAccountStatus(
    data: any,
    getAccessToken: (arg0: object) => Promise<string>
  ) {
    const accessToken = await getAccessToken({
      audience: config.AUTH0_AUDIENCE,
      scope: "read:current_user",
    });

    return baseService.put(`/users/status`, data, accessToken);
  }

  async getClaimRegistrants(
    queryString: string,
    getAccessToken: (arg0: object) => Promise<string>
  ) {
    const accessToken = await getAccessToken({
      audience: config.AUTH0_AUDIENCE,
      scope: "read:current_user",
    });

    const apiEndpoint = `/users/all?roles=Claimant&${queryString}`;
    return baseService.get(apiEndpoint, accessToken);
  }

  async getAllAdminUsers(
    queryString: string,
    getAccessToken: (arg0: object) => Promise<string>
  ) {
    const accessToken = await getAccessToken({
      audience: config.AUTH0_AUDIENCE,
      scope: "read:current_user",
    });

    const apiEndpoint = `/users/all?roles=Admin,Owner&${queryString}`;
    return baseService.get(apiEndpoint, accessToken);
  }
}
