import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      color: "#252066",
      backgroundColor: "#fff"
    },
    text1: {
      paddingLeft: "40px"
    },

    steps: {
      display: "flex"
    },

    vertical: {
      flexDirection: "column"
    },
    left: {
      paddingLeft: "100px",
      paddingBottom: "40px",
      paddingTop: "40px"
    },

    stepNum: {
      opacity: 0.35,
      width: 72,
      height: 161
    },

    desc: {
      color: "#333333"
    }
  })
);

export default useStyles;
