import React from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import logo from "../../../assets/logo@2x.png";
import footer from "../../../assets/home-footer.svg";
import { history } from "./../../../configureStore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "#252066",
      backgroundColor: "#fff",
      textAlign: "center",
      backgroundImage: `url(${footer})`,
      backgroundPosition: "center bottom",
      backgroundRepeat: "no-repeat",
    },

    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flex: "1 1 1",
    },

    image: {
      width: 225,
      height: 70,
      margin: "auto",
    },

    spacing: {
      marginRight: theme.spacing(2),
    },

    copyright: {
      opacity: 0.7,
      margin: "auto",
      marginTop: "11px",
      paddingLeft: "13%",
      paddingRight: "13%",
    },
    row: {
      margin: "auto",
      marginTop: "11px",
    },
    details: {
      paddingTop: "113px",
      display: "flex",
      flexDirection: "row",
      margin: "auto",
    },
    copyrightContainer: {
      display: "flex",
      flexDirection: "row",
      margin: "auto",
      paddingBottom: theme.spacing(5),
      marginTop: "51px",
    },
    company: {
      margin: "auto",
      alignContent: "center",
    },
    about: {
      margin: "auto",
      alignSelf: "center",
    },
    faq: {
      margin: "auto",
      alignContent: "center",
    },
    pressrelease: {
      margin: "auto",
      alignContent: "center",
    },
    privacyPolicy: {
      "&:hover": {
        cursor: "pointer",
        textDecorationLine: "underline",
      },
    },
    tAndc: {
      "&:hover": {
        cursor: "pointer",
        textDecorationLine: "underline",
      },
    },
  })
);

function RedirectToTermsPage() {
  history.push("/site/terms-of-use");
}

function RedirectToPrivacyPolicyPage() {
  history.push("/site/privacy-policy");
}

export default function HomeFooter() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="baseline"
        className={classes.details}
      >
        <Grid item sm={4} xs={12} lg={4} md={4} xl={4} className={classes.row}>
          <img src={logo} alt="logo" className={classes.image} />
        </Grid>
        <Grid item sm={4} md={4} xl={4} xs={12} lg={4} className={classes.row}>
          <Box
            fontSize={14}
            fontWeight="bold"
            letterSpacing={0.58}
            className={classes.company}
          >
            Company
          </Box>
          <Box fontSize={14} letterSpacing={0.58} className={classes.about}>
            <a href="/#aboutUs" style={{ textDecoration: "none" }}>
              ABOUT US
            </a>
          </Box>
          <Box fontSize={14} letterSpacing={0.58} className={classes.faq}>
            <Link to="/faq" style={{ textDecoration: "none" }}>
              FAQs
            </Link>
          </Box>
        </Grid>
        <Grid item sm={4} xs={12} lg={4} md={4} xl={4} className={classes.row}>
          <Box fontSize={14} fontWeight="bold" letterSpacing={0.58}>
            Contact Us
          </Box>
          <Box fontSize={14} letterSpacing={0.58}>
            <a
              href="mailto:info@prescientinnovations.com?subject= Attribution Ledger"
              style={{ textDecoration: "none" }}
            >
              info@prescientinnovations.com
            </a>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="baseline"
        className={classes.copyrightContainer}
      >
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
          <Box
            mt={5}
            fontSize={13}
            letterSpacing={0.54}
            className={classes.copyright}
          >
            &copy; 2021 Prescient Innovations Inc.
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Box
            mt={5}
            fontSize={13}
            letterSpacing={0.54}
            className={classes.copyright}
          >
            Innovation Powered by Access Copyright.
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
          <Box mt={5} pl={10} fontSize={12} className={classes.copyright}>
            <Typography
              onClick={RedirectToTermsPage}
              className={classes.tAndc}
              component="span"
            >
              TERMS OF USE
            </Typography>
            <strong> * </strong>
            <Typography
              onClick={RedirectToPrivacyPolicyPage}
              className={classes.privacyPolicy}
              component="span"
            >
              PRIVACY POLICY
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
