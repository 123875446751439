export enum ActionType {
  AUTH_SET,
  AUTH_RESET,
  ASSET_ADD,
  ASSET_UPDATE,
  ASSET_REMOVE,
  MAIN_IMAGE_UPDATE,
  CLAIM_ADD,
  CLAIM_DELETE,
  CLAIMS_ADD,
  CLAIM_RESET_STATE,
  USER_REGISTER,
  CLAIMSTATE_ADD,
  CLAIMSTATE_RESET,
  SET_USER_DATA,
  USER_DATA_RESET,
  VA_CLAIMSTATE_ADD,
  VA_CLAIMSTATE_RESET,
  REG_SET_ACCOUNT_TYPE,
  REG_SET_ROLETYPE,
  REG_SET_ORG_DETAILS,
  SET_USER_PROFILE,
  REG_SET_DOC_DETAILS,
  REG_SET_CURRENT_PAGE,
  RESET_REG_STATE,
  SET_USER_STATUS,
  SET_SEARCH_RESULTS,
  SET_SEARCH_RESULTS_LOADING,
  SET_SEARCH_CLAIM_TYPE,
  LOAD_ADMINS,
  RESET_ADMINS,
  AUTH_SET_STATUS,
  SET_CLAIM_REGISTRANT,
  CLAIM_REGISTRANT_RESET,
  SET_CLAIM_ERROR,
  RESET_CLAIM_ERROR,
  COUNTRIES_ADD,
  CURRENCIES_ADD,
  LANGUAGES_ADD,
  AUDIENCES_ADD,
  SUBJECTS_ADD,
  CONTRIBUTORTYPES_ADD,
  ARTFORMS_ADD,
  ARTMEDIUMS_ADD,
  WORK_URLS_UPDATE,
  USER_ID_UPDATE,
  MAIN_SUBJECTS_ADD,
}

export interface Action<T> {
  type: ActionType;
  payload?: T;
}
