import createReducer from "./createReducer";
import { Action, ActionType } from "models/actiontype";

export const claim = createReducer<any[]>([], {
  [ActionType.CLAIM_ADD](state: any[], action: Action<any>) {
    return [...state, action.payload];
  },
  [ActionType.CLAIM_DELETE](state: any[], action: Action<any>) {
    return [...state, action.payload];
  },
  [ActionType.CLAIMS_ADD](state: any[], action: Action<any[]>) {
    return action.payload;
  },
  [ActionType.ASSET_ADD](state: any[], action: Action<any[]>) {
    const assets =
      state[0] && state[0].assets !== undefined ? state[0].assets : [];
    assets.push(action.payload);
    return [{ ...state[0], assets }];
  },
  [ActionType.ASSET_UPDATE](state: any[], action: Action<any[]>) {
    const updatedAsset: any = action.payload;
    const assets = state[0].assets.map((asset: any) =>
      asset.filename === updatedAsset.filename ? updatedAsset : asset
    );
    return [{ ...state[0], assets }];
  },
  [ActionType.ASSET_REMOVE](state: any[], action: Action<any[]>) {
    const assets = state[0].assets.filter(
      (asset: any) => asset.filename !== action.payload
    );
    return [{ ...state[0], assets }];
  },
  [ActionType.MAIN_IMAGE_UPDATE](state: any[], action: Action<any[]>) {
    return [{ ...state[0], ...action.payload }];
  },
  [ActionType.WORK_URLS_UPDATE](state: any[], action: Action<any[]>) {
    return [{ ...state[0], workUrls: action.payload }];
  },
  [ActionType.USER_ID_UPDATE](state: any[], action: Action<any[]>) {
    return [{ ...state[0], userId: action.payload }];
  },
  [ActionType.CLAIM_RESET_STATE](state: [], action: Action<any>) {
    return [...state.splice(state.length)];
  },
});
