import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import WhatsOurPurposeCss from '../../styles/LandingPage/WhatsOurPurposeCss';
import WhatsOurPurposeImg from '../../assets/whatsourpurpose.svg';

export default function WhatsOurPurpose() {
  const classes = WhatsOurPurposeCss();
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <Typography className={classes.heading}>
            WHAT'S OUR PURPOSE
          </Typography>
          <Typography>
            As the ever-evolving digital space allows users to access a wealth
            of information and knowledge, it’s also made it increasingly
            difficult for digital services to ensure that the content they are
            making available is properly credited. The reliance on trust when
            content is added to a digital service means that we can never really
            be sure if the proper creators and rights holders have been attached
            to the work. The Attribution Ledger plays a critical role in
            addressing this issue and closing this gap. By verifying creator and
            rights information and connecting them directly to creative works,
            the ledger will empower creators and other rights holders to be
            properly paid when their works are used and provide assurance and
            integrity to digital services that the content they are using is
            properly attributed.
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item md={5} lg={5} xl={5}>
            <img src={WhatsOurPurposeImg} alt="What's Our Purpose" />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
}
