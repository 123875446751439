import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import { Typography, Grid, Button, Box, MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";
import clsx from "clsx";
import { forIn, camelCase, assign } from "lodash";
import { stringify } from "query-string";
import { history } from "configureStore";
import { trimAllFieldsInObjectAndChildren } from "utils/utility";
import LandingSearchDialogCss from "../../../styles/LandingPage/LandingSearchDialogCss";
import {
  MetaDataVisualArtsEnum,
  NarrowingMetaVisualArtsEnum,
} from "constants/MetaData";

import {
  ROWS_PER_PAGE_LANDING,
  SEARCH_VALIDATION_ERROR_MESSAGE,
} from "constants/AppConstants";

interface Props {
  handleClose: any;
  clearField: () => void;
}

function AdvancedSearchVA(props: Props) {
  const { handleClose, clearField } = props;
  const classes = LandingSearchDialogCss();

  const artForms = useSelector((state: RootState) => state.artForms);
  const artMediums = useSelector((state: RootState) => state.artMediums);

  const initialValues = {
    keyword: "",
    title: "",
    creator: "",
    additionalContributor: "",
    fromYear: "",
    toYear: "",
    artMedium: "",
    artForm: "",
  };

  const filtersVisualArts: { key: string; value: string }[] = [];
  forIn(MetaDataVisualArtsEnum, (item, index) => {
    filtersVisualArts.push({
      value: item,
      key: index,
    });
  });

  const narrowingVAEnum = forIn(NarrowingMetaVisualArtsEnum);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validate={(values) => {
        const errors: any = {};
        if (
          !values.keyword &&
          !values.title &&
          !values.creator &&
          !values.additionalContributor &&
          !values.fromYear &&
          !values.toYear &&
          !values.artMedium &&
          !values.artForm
        ) {
          errors.title = SEARCH_VALIDATION_ERROR_MESSAGE;
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const trimmedValues = trimAllFieldsInObjectAndChildren(values);
        let queryData: any = assign({}, trimmedValues);
        queryData.pageOffset = 0;
        queryData.pageSize = ROWS_PER_PAGE_LANDING;
        queryData.claimType = "VA";

        handleClose();
        clearField();
        const query = stringify(queryData, { sort: false });
        history.push({
          pathname: "/landing-search",
          search: `?${query}`,
        });
        setSubmitting(false);
      }}
    >
      {({ values, handleChange, errors }) => (
        <Form className={classes.container}>
          <Grid container className={classes.filterPalceholder}>
            {filtersVisualArts.map((item, index) => (
              <Grid container key={index}>
                <Grid item xs={4} className={classes.text}>
                  {item.value}
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    className={classes.textField}
                    id={`${item.value}-${index}`}
                    type="text"
                    margin="normal"
                    name={camelCase(item.value)}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Box m={2} mt={3} fontWeight={600} fontSize={16} lineHeight={1.25}>
            Narrowing
          </Box>
          <Box m={2} fontSize={14}>
            <Grid container>
              <Grid item xs={4} className={classes.text}>
                {narrowingVAEnum.COPYRIGHT_YEAR}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className={classes.dateVAField}
                  id="fromDate"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  name="fromYear"
                  onChange={handleChange}
                  value={values.fromYear}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className={classes.dateVAField}
                  id="toDate"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  name="toYear"
                  onChange={handleChange}
                  value={values.toYear}
                />
              </Grid>
            </Grid>
          </Box>
          <Box m={2} fontSize={14}>
            <Grid container className={classes.fields}>
              <Grid item xs={4} className={classes.text}>
                {narrowingVAEnum.ART_TYPE}
              </Grid>
              <Grid item xs={8}>
                <TextField
                  select
                  type="text"
                  value={values.artForm}
                  className={classes.textField}
                  id="artForm"
                  onChange={handleChange}
                  margin="normal"
                  name="artForm"
                >
                  {artForms.map((option: any) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container className={classes.fields}>
              <Grid item xs={4} className={classes.text}>
                {narrowingVAEnum.MEDIA}
              </Grid>
              <Grid item xs={8}>
                <TextField
                  select
                  type="text"
                  value={values.artMedium}
                  className={classes.textField}
                  id={narrowingVAEnum.MEDIA}
                  onChange={handleChange}
                  margin="normal"
                  name="artMedium"
                >
                  {artMediums.map((option: any) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            {/* <Grid container className={classes.fields}>
                            <Grid item xs={4} className={classes.text}>
                                {narrowingVAEnum.SURFACE}
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    type="text"
                                    className={classes.textField}
                                    id={narrowingVAEnum.SURFACE}
                                    onChange={handleChange}
                                    margin="normal"
                                    value={values.surface}
                                    name="surface"
                                >
                                </TextField>
                            </Grid>
                        </Grid> */}
            {errors.title && (
              <Typography
                className={classes.error}
                align="left"
                color="error"
                variant="body2"
                gutterBottom
              >
                {errors.title}
              </Typography>
            )}
          </Box>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item md={3}>
              <Button
                onClick={handleClose}
                variant="outlined"
                className={clsx(classes.button, classes.cancelButton)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item md={3}>
              <Button
                type="submit"
                variant="contained"
                className={clsx(classes.button, classes.approveButton)}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default AdvancedSearchVA;
