import { Action, ActionType } from "../models/actiontype";
import createReducer from "./createReducer";
import { ClaimError } from "models/Error";

const initialState = {
  error: null,
};

export const claimErrorState = createReducer<ClaimError>(initialState, {
  [ActionType.SET_CLAIM_ERROR](state: ClaimError, action: Action<any>) {
    return { ...state, error: action.payload };
  },
  [ActionType.RESET_CLAIM_ERROR]() {
    return initialState;
  },
});
