import { createDefaultAddressInformation } from "./AddressInformation";
import { Address } from "./Address";

export interface UserInfo {
  id: string | null;
  userId: string | null;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Date | null;
  dateOfDeath: Date | null;
  email: string;
  phoneNumber: string;
  isni: number;
  address: Array<Address>;
  isTrusted?: boolean;
}

export const createDefaultUserInfo = () => {
  return {
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: null,
    dateOfDeath: null,
    email: "",
    phoneNumber: "",
    isni: NaN,
    userId: "",
    address: [createDefaultAddressInformation()],
    isTrusted: false,
  };
};
