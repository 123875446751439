import { Action, ActionType } from "../models/actiontype";
import { Audience } from "../models/Audience";

import createReducer from "./createReducer";

export const audiences = createReducer<Audience[]>([], {
  [ActionType.AUDIENCES_ADD](state: Audience[], action: Action<Audience[]>) {
    return action.payload;
  },
});
