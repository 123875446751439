import { Action, ActionType } from "../models/actiontype";
import { Auth } from "../models/auth";
import { UserStatus } from "constants/UserStatus";

export function setUser(auth: Auth): Action<Auth> {
  return {
    type: ActionType.AUTH_SET,
    payload: auth,
  };
}
export function setUserStatus(userStatus: UserStatus): Action<UserStatus> {
  return {
    type: ActionType.AUTH_SET_STATUS,
    payload: userStatus,
  };
}

export function resetAuthState(): Action<Auth> {
  return {
    type: ActionType.AUTH_RESET,
  };
}
