import createReducer from "./createReducer";
import { Action, ActionType } from "models/actiontype";
import { Auth } from "models/auth";
import { UserStatus } from "constants/UserStatus";

const initialState = {
  id: "",
  role: "",
  email: "",
  status: UserStatus.PENDING,
  isEmailVerified: false,
};

export const auth = createReducer<Auth>(initialState, {
  [ActionType.AUTH_SET](state: Auth, action: Action<Auth>) {
    return action.payload;
  },
  [ActionType.AUTH_SET_STATUS](state: Auth, action: Action<UserStatus>) {
    return { ...state, status: action.payload };
  },
});
