import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface LandingSearchProps {
  width?: any;
}

const useStyles = makeStyles<Theme, LandingSearchProps>((theme: Theme) =>
  createStyles({
    root: (props) => ({
      width: "100%",
      margin: "auto",
    }),
    checkboxesRoot: (props) => ({
      width: "100%",
      margin: "auto",
      marginTop: "-5px",
    }),
    labelColor: {
      color: "rgb(0 0 0 / 50%)",
    },
    checkboxes: (props) => ({
      paddingLeft: 10,
      border: "solid 1px rgba(37, 32, 102, 0.3)",
      background: "#edecf5",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      [theme.breakpoints.down("sm")]: {
        height: 44,
      },
    }),
    field: {
      paddingLeft: theme.spacing(1),
      paddingBottom: 5,
      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
      border: "solid 1px rgba(37, 32, 102, 0.3)",
    },
    panel: {
      marginBottom: "10px",
      backgroundColor: "#dee8f1",
      minHeight: "66px",
    },
    heading: {
      paddingTop: "10px",
      fontWeight: 600,
    },
    details: {
      backgroundColor: "#ffffff",
      display: "flex",
      flexDirection: "column",
    },
    button: {
      float: "right",
      marginRight: theme.spacing(15),
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      width: 133,
      height: 44,
      backgroundColor: "#241f63",
      color: "white",
      fontWeight: "bold",
    },
    cancelButton: {
      float: "right",
      width: 133,
      height: 44,
      color: "#202f35",
      fontWeight: "bold",
    },
    fields: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      position: "relative",
    },
    iconButton: {
      padding: 10,
      paddingBottom: 10,
      "& svg": {
        width: "1.4em",
        height: "1.4em",
      },
    },
    advSearch: {
      float: "right",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    boxText: {
      marginTop: 5,
      color: "#042b6b",
      textDecoration: "underline",
      textTransform: "capitalize",
    },
    input: {
      width: "100%",
      float: "left",
    },
    text: {
      position: "absolute",
      top: "50%",
      left: 0,
    },
    textCard: {
      fontWeight: 600,
      marginLeft: "33%",
    },
    paper: {
      paddingTop: 20,
      paddingBottom: 16,
      marginTop: theme.spacing(3),
      height: "124px",
      width: "220px",
      border: "solid 1px #cdd4d7",
      marginRight: "30px",
    },
    img: {
      width: "54.7px",
      height: "36px",
      alignItems: "center",
      marginTop: "10%",
      marginLeft: "33%",
    },
    bordered: {
      paddingTop: 20,
      paddingBottom: 16,
      marginTop: theme.spacing(3),
      height: "124px",
      width: "220px",
      border: "2px solid #252066",
      borderRadius: 2,
      boxShadow: "0 1px 1px 0 rgba(98, 104, 111, 0.16)",
      marginRight: "30px",
    },
    textField: {
      width: "100%",
      marginLeft: theme.spacing(2),
    },
    container: {
      overflowX: "hidden",
      width: "100%",
      maxWidth: "640px",
      height: "833px",
      backgroundColor: "#ffffff",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

export default useStyles;
