import React, { useEffect, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, RouteComponentProps, Router, Switch } from "react-router-dom";
import { history } from "./configureStore";
import smartlookClient from "smartlook-client";
import { isSupportedBrowser } from "./utils/utility";
import { RootState } from "./reducers/index";
import withRoot from "./withRoot";
import { Auth } from "./models/auth";
import BrowserWarning from "./components/BrowserWarning";
import FullPageLoader from "./components/common/FullPageLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useActions } from "./actions";
import * as UserActions from "./actions/user";
import * as LandingPageSearchAction from "./actions/landing.search";
import config from "./config";
import LandingPage from "./pages/LandingPage";
import OAuthPage from "./pages/OAuthPage";
const Public = lazy(() => import("./routes/public"));
const Private = lazy(() => import("./routes/private"));
const Admin = lazy(() => import("./routes/admin"));

toast.configure({
  position: "bottom-left",
  autoClose: 6000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
});

function Routes() {
  const { user, isAuthenticated } = useAuth0();
  useEffect(() => {
    // Init Smartlook for video capture
    smartlookClient.init(config.SMARTLOOK_KEY);
  }, []);

  useEffect(() => {
    if (!user || !isAuthenticated) {
      return;
    }
    smartlookClient.identify(user.email, { ...user });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  return (
    <>
      {!isSupportedBrowser() && <BrowserWarning />}
      <Switch>
        <Suspense fallback={<FullPageLoader />}>
          <Route path="/" component={Public} />
          <Route path="/user" component={Private} />
          <Route path="/admin" component={Admin} />
          <Route exact={true} path="/oauth" component={OAuthPage} />
        </Suspense>
        <Route exact path="/" component={LandingPage} />
        <Route component={() => <h1>Not Found</h1>} />
      </Switch>
    </>
  );
}

interface Props extends RouteComponentProps<void> {
  auth: Auth;
}

function App(props?: Props) {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const userActions = useActions(UserActions);
  const landingPageSearchAction = useActions(LandingPageSearchAction);

  useEffect(() => {
    if (!user || !isAuthenticated) {
      return;
    }
    userActions.getUser(getAccessTokenSilently);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  useEffect(() => {
    landingPageSearchAction.fetchCountries();
    landingPageSearchAction.fetchCurrencies();
    landingPageSearchAction.fetchLanguages();
    landingPageSearchAction.fetchAudiences();
    landingPageSearchAction.fetchSubjects();
    landingPageSearchAction.fetchMainSubjects();
    landingPageSearchAction.fetchContributorTypes();
    landingPageSearchAction.fetchArtForms();
    landingPageSearchAction.fetchArtMediums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
}

function mapStateToProps(state: RootState) {
  return {
    auth: state.auth,
    claimState: state.claimstate,
    users: state.users,
    registrationState: state.registrationState,
    landingSearchState: state.landingSearchState,
    admins: state.admins,
    claimRegistrant: state.claimRegistrant,
    claimErrorState: state.claimErrorState,
    countries: state.countries,
    languages: state.languages,
    audiences: state.audiences,
    subjects: state.subjects,
    contributorTypes: state.contributorTypes,
    artForms: state.artForms,
    artMediums: state.artMediums,
  };
}

export default connect(mapStateToProps)(withRoot(App));
