import { Action, ActionType } from "../models/actiontype";
import { ContributorType } from "../models/ContributorType";

import createReducer from "./createReducer";

export const contributorTypes = createReducer<ContributorType[]>([], {
  [ActionType.CONTRIBUTORTYPES_ADD](
    state: ContributorType[],
    action: Action<ContributorType[]>
  ) {
    return action.payload;
  },
});
