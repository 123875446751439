import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import LandingPageCss from '../styles/LandingPage/LandingPageCss';
import LandingSearchBox from 'components/LandingPage/LandingSearchBox';
import AttributionLandingSteps from 'components/LandingPage/AttributionLandingSteps';
import PartnersAndFeaturedIn from 'components/LandingPage/Footer/PartnersAndFeaturedIn';
import LandingPageNavHeader from 'components/LandingPage/Header/LandingPageNavHeader';
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import WhatsOurPurpose from 'components/LandingPage/WhatsOurPurpose';
import { LANDING_PAGE_VIDEO } from "constants/AppConstants";

interface Props extends RouteComponentProps<void> {}

export default function LandingPage(props: Props) {
  const { history, location } = props;
  const classes = LandingPageCss();

  function faq() {
    history.push({
      pathname: "/faq",
    });
  }

  function attestationProvider() {
    history.push({
      pathname: "/attestation-provider",
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <LandingPageNavHeader />
        <Typography component="div" className={classes.text}>
          <Hidden mdUp>
            <Box
              className={classes.main}
              textAlign="center"
              fontFamily="Open Sans"
              lineHeight={0.95}
              letterSpacing={1.71}
              fontSize={41}
              fontWeight={800}
            >
              Attribution Ledger
            </Box>
            <Box
              m={2}
              className={classes.description}
              letterSpacing={0.58}
              fontWeight={600}
              textAlign="center"
              fontSize={14}
            >
              The Attribution Ledger has been developed with creators in mind
              and will establish an authoritative (immutable), verified
              connection between a work, its creator and its rightsholders in a
              manner that is cryptographically secure, privacy respecting and
              machine-readable.
            </Box>
          </Hidden>
          <Hidden smDown>
            <Box
              className={classes.main}
              textAlign="center"
              fontFamily="Open Sans"
              lineHeight={0.95}
              letterSpacing={2.54}
              fontSize={64}
              fontWeight={800}
            >
              Attribution Ledger
            </Box>
            <Box
              m={2}
              className={classes.description}
              letterSpacing={0.58}
              fontWeight={600}
              textAlign="center"
              fontSize={17}
            >
              The Attribution Ledger has been developed with creators in mind
              and will establish an authoritative (immutable), verified
              connection between a work, its creator and its rightsholders in a
              manner that is cryptographically secure, privacy respecting and
              machine-readable.
            </Box>
          </Hidden>
        </Typography>
        <Box className={classes.search}>
          <LandingSearchBox placeholder={"Search thousands of works"} width="80%" location={location} />
        </Box>
      </div>

      <Grid container className={classes.mediaContainer}>
        <Hidden mdUp>
          <Grid item xs={10} sm={10} id="whyItMatter">
            <Box
              className={classes.mobilemediaDesc}
              fontWeight={600}
              fontSize={29}
            >
              Why is reliable, authoratative, attribution important?
            </Box>
          </Grid>
        </Hidden>
        <Grid item xs={10} sm={10} md={6}>
          <iframe
            title="Landing Page Why it Matters video"
            className={classes.media}
            src={LANDING_PAGE_VIDEO}
            frameBorder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography component="div" className={classes.videoDesc}>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="center"
            >
              <Hidden smDown>
                <Box
                  className={classes.mediaDesc}
                  fontWeight={600}
                  fontSize={29}
                  id="whyItMatter"
                >
                  Why is reliable, authoratative, attribution important?
                </Box>
              </Hidden>
              <Hidden smDown>
                <Box
                  m={1}
                  className={classes.mediaDesc}
                  fontSize={17}
                  lineHeight={1.41}
                  letterSpacing={0.85}
                >
                  Verified attribution is critical to truly fulfilling the
                  promise of the internet. By establishing a secure and
                  tamper-evident connection between a work, its creator and its
                  rightsholders, digital services can ensure that content they
                  are sharing is rightfully attributed and for the right
                  creators to be paid.
                </Box>
              </Hidden>

              <Grid item xs={12} sm={12}>
                <Hidden mdUp>
                  <Link
                    component="button"
                    className={classes.faq}
                    variant="body2"
                    onClick={() => faq()}
                  >
                    FAQs
                  </Link>
                </Hidden>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => attestationProvider()}
                >
                  Become an Attestation Provider
                </Button>
              </Grid>
              <Grid item md={12} lg={12} xl={12}>
                <Hidden smDown>
                  <Link
                    component="button"
                    className={classes.faq}
                    variant="body2"
                    onClick={() => faq()}
                  >
                    FAQs
                  </Link>
                </Hidden>
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </Grid>
      <Box id="aboutUs">
        <WhatsOurPurpose />
      </Box>
      <AttributionLandingSteps />
      <PartnersAndFeaturedIn />
    </div>
  );
}
