import axios from "axios";

const axiosService = axios.create({
  timeout: 100000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// For Requests
axiosService.interceptors.request.use(
  async function (config) {
    return config;
  },
  function (error) {
    return error;
  }
);

// For Responses
axiosService.interceptors.response.use(function (response) {
  // Add debug logging
  return response;
});

export default axiosService;
