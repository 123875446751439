import React, { useState, useEffect } from "react";
import { isEmpty, trim, assign } from "lodash";
import { stringify } from "query-string";
import { history } from "configureStore";
import queryString from "query-string";
import Box from "@material-ui/core/Box";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import { Typography } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import LandingSearchDialog from "./AdvancedSearch/LandingSearchDialog";
import SearchBoxCss from "../../styles/LandingPage/LandingSearchCss";
import {
  SEARCH_VALIDATION_ERROR_MESSAGE,
  ROWS_PER_PAGE_LANDING,
} from "constants/AppConstants";

interface Props {
  query?: any;
  width?: string;
  placeholder: string;
  location?: any;
}

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiButton: {
      root: {
        color: "#003a99",
      },
    },
  },
});

export default function LandingSearchBox(props: Props) {
  const { placeholder, query, location } = props;
  const queryValue = queryString.parse(location.search);
  const [queryData, setQueryData] = useState(query || "");
  const [searchBook, setSearchBook] = useState(
    (queryValue.claimType && queryValue.claimType === "BOOK") || false
  );
  const [searchVA, setSearchVA] = useState(
    (queryValue.claimType && queryValue.claimType === "VA") || false
  );
  const [dialog, setDialog] = useState(false);
  const classes = SearchBoxCss(props);
  const [searchError, setSearchError] = useState(false);

  function openDialog() {
    setSearchError(false);
    setDialog(true);
  }

  function closeDialog() {
    setDialog(false);
  }

  const handleBasicSearch = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onSearchButtonClick();
    }
  };

  const onSearchButtonClick = async () => {
    if (isSearchFieldEmpty()) {
      setSearchError(true);
      return;
    }

    const data = {
      query: trim(queryData),
      pageOffset: 0,
      pageSize: ROWS_PER_PAGE_LANDING,
    };
    let params: any = data;
    if (searchBook && !searchVA) params = assign(data, { claimType: "BOOK" });
    if (!searchBook && searchVA) params = assign(data, { claimType: "VA" });

    const query = stringify(params, { sort: false });
    history.push({
      pathname: "/landing-search",
      search: `?${query}`,
    });
  };

  const changeClaimType = (type: string, value: boolean) => {
    if (type === "BOOK") setSearchBook(value);
    if (type === "VA") setSearchVA(value);
  };

  const isSearchFieldEmpty = (): boolean => {
    return isEmpty(trim(queryData));
  };

  const handleChange = (event: any) => {
    if (!isEmpty(trim(event.target.value))) {
      setSearchError(false);
    }
    setQueryData(event.target.value);
  };

  const clearSearchField = () => {
    setQueryData("");
  };

  useEffect(() => {
    setQueryData(query);
  }, [query]);

  useEffect(() => {
    if (
      (searchBook && searchVA && queryValue.claimType) ||
      (!searchBook && !searchVA && queryValue.claimType)
    ) {
      delete queryValue.claimType;
    } else if (searchBook && !searchVA) {
      queryValue.claimType = "BOOK";
    } else if (!searchBook && searchVA) {
      queryValue.claimType = "VA";
    }
  }, [searchBook, searchVA]);

  useEffect(() => {
    setSearchBook(
      (queryValue.claimType && queryValue.claimType === "BOOK") || false
    );
    setSearchVA(
      (queryValue.claimType && queryValue.claimType === "VA") || false
    );
  }, [location]);

  return (
    <MuiThemeProvider theme={theme}>
      <Box mt={6} className={classes.root}>
        <Paper className={classes.field}>
          <Grid container style={{ paddingLeft: "5px" }}>
            <Grid item xs={12}>
              <InputBase
                className={classes.input}
                placeholder={placeholder}
                inputProps={{ "aria-label": "search" }}
                onKeyDown={handleBasicSearch}
                onChange={handleChange}
                value={queryData || ""}
                endAdornment={
                  <IconButton
                    className={classes.iconButton}
                    aria-label="menu"
                    onClick={onSearchButtonClick}
                  >
                    <SearchIcon />
                  </IconButton>
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box mt={6} className={classes.checkboxesRoot}>
        <Paper className={classes.checkboxes}>
          <Grid container>
            <Grid item xs={4} md={2} lg={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={searchBook}
                    onClick={(event) => {
                      changeClaimType("BOOK", !searchBook);
                    }}
                  />
                }
                label={"Book"}
                className={classes.labelColor}
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={8} md={3} lg={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={searchVA}
                    onClick={(event) => {
                      changeClaimType("VA", !searchVA);
                    }}
                  />
                }
                className={classes.labelColor}
                label={"Visual Art"}
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={12} md={7} lg={9}>
              <Button className={classes.advSearch} onClick={openDialog}>
                <Typography variant="body2" className={classes.boxText}>
                  Advanced Search
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
        {searchError && (
          <Box mt={1} mb={2}>
            <Typography align="left" color="error" variant="body2" gutterBottom>
              {SEARCH_VALIDATION_ERROR_MESSAGE}
            </Typography>
          </Box>
        )}
        {/* Dialog */}
        {dialog && (
          <LandingSearchDialog
            open={dialog}
            closeDialog={closeDialog}
            clearField={clearSearchField}
          />
        )}
      </Box>
    </MuiThemeProvider>
  );
}
