import baseService from "./baseService";
import config from "../config";

export default class ClaimService {
  async getAllClaims(queryStringParams: string, getAccessToken?: any) {
    let accessToken = null;
    if (getAccessToken)
      accessToken = await getAccessToken({
        audience: config.AUTH0_AUDIENCE,
        scope: "read:current_user",
      });
    const apiEndpoint = queryStringParams
      ? `/claims/` + `?${queryStringParams}`
      : `/claims/`;

    return accessToken
      ? baseService.get(apiEndpoint, accessToken)
      : baseService.get(apiEndpoint);
  }

  async getClaimsCount(
    getAccessToken: (arg0: object) => Promise<string>,
    claimType?: any
  ) {
    const accessToken = await getAccessToken({
      audience: config.AUTH0_AUDIENCE,
      scope: "read:current_user",
    });

    const apiEndpoint = claimType
      ? `/claims/totals` + `?claimType=${claimType}`
      : `/claims/totals`;

    return baseService.get(apiEndpoint, accessToken);
  }

  async getClaim(id: string, getAccessToken?: any) {
    let accessToken = null;
    if (getAccessToken)
      accessToken = await getAccessToken({
        audience: config.AUTH0_AUDIENCE,
        scope: "read:current_user",
      });
    return accessToken
      ? baseService.get(`/claims/${id}`, accessToken)
      : baseService.get(`/claims/${id}`);
  }

  async upsertClaim(
    data: any,
    getAccessToken: (arg0: object) => Promise<string>
  ) {
    const accessToken = await getAccessToken({
      audience: config.AUTH0_AUDIENCE,
      scope: "read:current_user",
    });
    return baseService.put(`/claims/`, data, accessToken);
  }

  async uploadAsset(
    file: any,
    type: string,
    privateFile: boolean,
    getAccessToken: (arg0: object) => Promise<string>
  ) {
    const accessToken = await getAccessToken({
      audience: config.AUTH0_AUDIENCE,
      scope: "read:current_user",
    });
    return baseService.uploadFile(file, type, privateFile, accessToken);
  }

  async updateClaimsStatuses(
    data: any,
    getAccessToken: (arg0: object) => Promise<string>
  ) {
    const accessToken = await getAccessToken({
      audience: config.AUTH0_AUDIENCE,
      scope: "read:current_user",
    });
    return baseService.put(`/claims/status`, data, accessToken);
  }

  async deleteClaim(
    id: any,
    getAccessToken: (arg0: object) => Promise<string>
  ) {
    const accessToken = await getAccessToken({
      audience: config.AUTH0_AUDIENCE,
      scope: "read:current_user",
    });
    return baseService.delete(`/claims/${id}`, accessToken);
  }
}
