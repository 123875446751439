import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "auto",
      paddingTop: "39px",
      paddingBottom: "39px",
      paddingLeft: "37px",
      paddingRight: "37px",
      backgroundColor: "#f3f8fa"
    },
    heading: {
      fontSize: "30px",
      fontWeight: 600,
      lineHeight: 1.77,
      letterSpacing: "1.25px",
      color: "#2d2374"
    }
  })
);

export default useStyles;
