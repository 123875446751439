import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import BgImg from "../../assets/landing-page.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#e0ebfd",
      backgroundImage: `url(${BgImg})`,
      height: "auto",
      width: "100%",
      paddingTop: 10,
      position: "relative",
      [theme.breakpoints.up("sm")]: {
        paddingTop: 23,
      },
    },
    header: {
      width: "100%",
      height: "auto",
      backgroundSize: "cover",
    },

    paper: {
      width: "90%",
      display: "flex",
      flexDirection: "row",
      margin: "auto",
    },

    search: {
      position: "relative",
      zIndex: 5,
      marginLeft: 35,
      marginRight: 35,
      left: 0,
      right: 0,
      paddingTop: "40px",
      paddingBottom: "106px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: 15,
        marginRight: 15,
      },
    },

    image: {
      width: 147,
      height: 63,
      margin: theme.spacing(2),
    },

    navHeaders: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      width: "75%",
    },

    text: {
      color: "#252066",
      paddingLeft: "18.8%",
      paddingRight: "18.8%",
    },

    iconButton: {
      padding: 10,
      marginRight: theme.spacing(3.5),
    },

    main: {
      marginTop: theme.spacing(12),
    },

    title: {
      color: "#252066",
    },

    description: {
      maxWidth: 638,
      margin: "15px auto",
    },

    mediaContainer: {
      backgroundColor: "#fff",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      paddingTop: theme.spacing(6),
    },

    media: {
      maxWidth: "538px",
      width: "100%",
      height: "347px",
      padding: theme.spacing(2),
      marginLeft: theme.spacing(4),
    },

    mediaDesc: {
      width: 316,
      color: "#252066",
      marginBottom: theme.spacing(3),
      marginRight: theme.spacing(6),
    },
    mobilemediaDesc: {
      width: 353,
      color: "#252066",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      marginRight: "2.5%",
      marginLeft: "2.5%",
      paddingLeft: "9px",
      fontWeight: "bold",
    },
    faq: {
      color: "#252066",
      paddingLeft: "20%",
      fontSize: "20px",
      fontWeight: 600,
      margin: "auto",
    },
    videoDesc: {
      margin: "auto",
      marginLeft: theme.spacing(5),
      marginBottom: theme.spacing(7),
    },
    button: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      maxwidth: "302",
      height: 48,
      alignContent: "center",
    },

    nolink: {
      textDecoration: "none",
      color: "#252066",
    },
  })
);

export default useStyles;
