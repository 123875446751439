import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import LandingStepsCss from '../../styles/LandingPage/AttributionLandingStepsCss';
import StepOne from '../../assets/attributionstep1.jpg';
import StepTwo from '../../assets/attributionstep2.jpg';
import StepThree from '../../assets/attributionstep3.svg';

export default function AttributionLandingSteps() {
  const classes = LandingStepsCss();
  return (
    <Typography component="div" className={classes.container}>
      <Box p={5} fontSize={41} fontWeight={600} textAlign="center">
        Attribution in 3 steps
      </Box>
      <Box className={classes.steps}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item lg={5} md={6} xl={4}>
            <Hidden smDown>
              <img src={StepOne} alt="step-1" className={classes.left} />
            </Hidden>
          </Grid>
          <Grid
            item
            lg={7}
            xs={12}
            sm={12}
            md={6}
            xl={8}
            className={classes.text1}
          >
            <Box className={classes.steps}>
              <Box
                fontSize={137}
                fontWeight={600}
                fontFamily="Raleway"
                letterSpacing={6.85}
                className={classes.stepNum}
              >
                1
              </Box>
              <Box m={5} className={classes.vertical}>
                <Box
                  fontSize={17}
                  letterSpacing={0.85}
                  className={classes.desc}
                >
                  Register with the Attribution Ledger to create your account
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Hidden mdUp>
        <div className={classes.text1}>
          <Box className={classes.steps}>
            <Box
              fontSize={137}
              fontWeight={600}
              fontFamily="Raleway"
              letterSpacing={6.85}
              className={classes.stepNum}
            >
              2
            </Box>

            <Box m={5} className={classes.vertical}>
              <Box fontSize={17} letterSpacing={0.85} className={classes.desc}>
                Submit a claim of ownership
              </Box>
            </Box>
          </Box>
        </div>
      </Hidden>
      <Hidden smDown>
        <Box m={2} className={classes.steps}>
          <Grid
            container
            justify="space-around"
            direction="row"
            spacing={2}
            alignItems="center"
          >
            <Grid item lg={7} md={6} xs={12} sm={12} xl={8}>
              <Box className={classes.steps} ml={15}>
                <Box
                  fontSize={137}
                  fontWeight={600}
                  fontFamily="Raleway"
                  letterSpacing={6.85}
                  className={classes.stepNum}
                >
                  2
                </Box>
                <Box m={5} className={classes.vertical}>
                  <Box
                    fontSize={17}
                    letterSpacing={0.85}
                    className={classes.desc}
                  >
                    Submit a claim of ownership
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item lg={5} md={6} xl={4}>
              <img src={StepTwo} alt="step-2" />
            </Grid>
          </Grid>
        </Box>
      </Hidden>

      <Box className={classes.steps}>
        <Grid container justify="space-between" alignItems="center">
          <Hidden smDown>
            <Grid item lg={5} md={6} xl={4}>
              <img src={StepThree} alt="step-3" className={classes.left} />
            </Grid>
          </Hidden>

          <Grid
            item
            lg={7}
            xs={12}
            sm={12}
            xl={8}
            md={6}
            className={classes.text1}
          >
            <Box className={classes.steps}>
              <Box
                fontSize={137}
                fontWeight={600}
                fontFamily="Raleway"
                letterSpacing={6.85}
                className={classes.stepNum}
              >
                3
              </Box>
              <Box m={5} className={classes.vertical}>
                <Box
                  fontSize={17}
                  letterSpacing={0.85}
                  className={classes.desc}
                >
                  The Attribution Ledger establishes an authoritative link
                  between you and your work
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Typography>
  );
}
