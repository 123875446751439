import { AdminUsers } from "./../models/AdminUsers";
import createReducer from "./createReducer";
import { Action, ActionType } from "models/actiontype";

let initialState: AdminUsers[] = [];

export const admins = createReducer<AdminUsers[]>(initialState, {
  [ActionType.LOAD_ADMINS](state: AdminUsers[], action: Action<AdminUsers[]>) {
    return action.payload;
  },

  [ActionType.RESET_ADMINS](state: []) {
    return [...state.splice(state.length)];
  },
});
