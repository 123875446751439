import { AdminUsers } from "./../models/AdminUsers";
import { History } from "history";
import { combineReducers } from "redux";
import * as auth from "./auth";
import * as claim from "./claim";
import * as claimstate from "./claimstate";
import * as registrationState from "./registrationstate";
import * as landingSearchState from "./landingsearch";
import * as users from "./userManagement";
import * as admins from "./admins";
import * as claimRegistrant from "./claimRegistrant";
import * as countries from "./countries";
import * as currencies from "./currencies";
import * as languages from "./languages";
import * as audiences from "./audiences";
import * as subjects from "./subjects";
import * as mainSubjects from "./mainSubjects";
import * as contributorTypes from "./contributorTypes";
import * as artForms from "./artForms";
import * as artMediums from "./artMediums";
import * as claimErrorState from "./claimerrorstate";
import { ClaimState } from "models/claimstate";
import { Auth } from "models/auth";
import { UserManagement } from "models/UserManagement";
import { Registration } from "models/Registration";
import { LandingSearchResults } from "models/LandingSearchResult";
import { ClaimRegistrantsData } from "models/ClaimRegistrantsDetails";
import { ClaimError } from "models/Error";
import { Country } from "models/Country";
import { Currency } from "models/Currency";
import { Language } from "models/Language";
import { Audience } from "models/Audience";
import { Subject } from "models/Subject";
import { ContributorType } from "models/ContributorType";
import { ArtForm } from "models/ArtForm";
import { ArtMedium } from "models/ArtMedium";
export interface RootState {
  auth: Auth;
  claim: any[];
  claimstate: ClaimState[];
  users: UserManagement[];
  registrationState: Registration;
  landingSearchState: LandingSearchResults;
  admins: AdminUsers[];
  claimRegistrant: ClaimRegistrantsData;
  claimErrorState: ClaimError;
  countries: Country[];
  currencies: Currency[];
  languages: Language[];
  audiences: Audience[];
  subjects: Subject[];
  mainSubjects: Subject[];
  contributorTypes: ContributorType[];
  artForms: ArtForm[];
  artMediums: ArtMedium[];
}

const allReducers = Object.assign(
  {},
  auth,
  claim,
  claimstate,
  users,
  registrationState,
  landingSearchState,
  admins,
  claimRegistrant,
  claimErrorState,
  countries,
  currencies,
  languages,
  audiences,
  subjects,
  mainSubjects,
  contributorTypes,
  artForms,
  artMediums
);

export default (history: History) => combineReducers({ ...allReducers });
