import {
  ContactInformation,
  createDefaultContactInformation,
} from "./ContactInformation";
import { Address } from "./Address";
import { GovernmentId, createDefaultGovernmentIdObject } from "./OrgInfoDocs";
import { createDefaultAddressInformation } from "models/AddressInformation";

export interface BaseOrganizationInfo {
  id?: string;
  isTrusted?: boolean;
  organizationName: string;
  websiteUrl: string;
  phoneNumber: string;
  parentCompany: string;
  subsidiaryCompany: Array<string>;
  orgDocs: GovernmentId;
}

export interface OrganizationInfo extends BaseOrganizationInfo {
  contacts: Array<ContactInformation>;
  address: Array<Address>;
}

export const createDefaultOrganizationInfo = () => {
  return {
    organizationName: "",
    websiteUrl: "",
    phoneNumber: "",
    parentCompany: "",
    address: [createDefaultAddressInformation()],
    subsidiaryCompany: [""],
    contacts: [createDefaultContactInformation()],
    orgDocs: createDefaultGovernmentIdObject(),
  };
};
