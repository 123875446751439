import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import { Typography, Grid, Button, Box, MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { forIn, assign } from "lodash";
import { history } from "configureStore";
import { stringify } from "query-string";
import { Formik, Form } from "formik";
import { trimAllFieldsInObjectAndChildren, titleCase } from "utils/utility";
import LandingSearchDialogCss from "../../../styles/LandingPage/LandingSearchDialogCss";
import { MetaDataBookEnum, NarrowingMetaBookEnum } from "constants/MetaData";
import { camelCase } from "lodash";

import {
  ROWS_PER_PAGE_LANDING,
  SEARCH_VALIDATION_ERROR_MESSAGE,
} from "constants/AppConstants";

interface Props {
  handleClose: any;
  clearField: () => void;
}

function AdvancedSearchEbook(props: Props) {
  const { handleClose, clearField } = props;
  const classes = LandingSearchDialogCss();

  const audiences = useSelector((state: RootState) => state.audiences);
  const mainSubjects = useSelector((state: RootState) => state.mainSubjects);
  const initialValues = {
    keyword: "",
    isbn: "",
    title: "",
    contributor: "",
    publisher: "",
    fromYear: "",
    toYear: "",
    subject: "",
    audience: "",
  };

  const filters: { key: string; value: string }[] = [];
  forIn(MetaDataBookEnum, (item, index) => {
    filters.push({
      value: item,
      key: index,
    });
  });

  const narrowingBookEnum = forIn(NarrowingMetaBookEnum);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validate={(values) => {
        const errors: any = {};
        if (
          !values.keyword &&
          !values.title &&
          !values.isbn &&
          !values.contributor &&
          !values.publisher &&
          !values.fromYear &&
          !values.toYear &&
          !values.subject &&
          !values.audience
        ) {
          errors.title = SEARCH_VALIDATION_ERROR_MESSAGE;
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const trimmedValues = trimAllFieldsInObjectAndChildren(values);
        let queryData: any = assign({}, trimmedValues);
        queryData.pageOffset = 0;
        queryData.pageSize = ROWS_PER_PAGE_LANDING;
        queryData.claimType = "BOOK";
        handleClose();
        clearField();
        const query = stringify(queryData, { sort: false });
        history.push({
          pathname: "/landing-search",
          search: `?${query}`,
        });
        setSubmitting(false);
      }}
    >
      {({ values, handleChange, errors }) => (
        <Form className={classes.container}>
          <Grid container className={classes.filterPalceholder}>
            {filters.map((item, index) => (
              <Grid container key={index}>
                <Grid item xs={4} className={classes.text}>
                  {item.value}
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    className={classes.textField}
                    id={`${item.value}-${index}`}
                    type="text"
                    margin="normal"
                    name={camelCase(item.value)}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Box m={2} mt={3} fontWeight={600} fontSize={16} lineHeight={1.25}>
            Narrowing
          </Box>
          <Box m={2} fontSize={14}>
            <Grid container>
              <Grid item xs={3} className={classes.text}>
                {narrowingBookEnum.DATE_PUBLISHED}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className={classes.dateField}
                  id="fromDate"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  name="fromYear"
                  label="From year"
                  onChange={handleChange}
                  value={values.fromYear}
                />
              </Grid>
              <Grid item xs={4} className={classes.dateToField}>
                <TextField
                  id="toDate"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  name="toYear"
                  label="To year"
                  onChange={handleChange}
                  value={values.toYear}
                />
              </Grid>
            </Grid>
          </Box>
          <Box m={2} fontSize={14}>
            <Grid container className={classes.fields}>
              <Grid item xs={3} className={classes.text}>
                {narrowingBookEnum.SUBJECT}
              </Grid>
              <Grid item xs={9}>
                <TextField
                  select
                  type="text"
                  value={values.subject}
                  className={classes.textField}
                  id={narrowingBookEnum.SUBJECT}
                  onChange={handleChange}
                  margin="normal"
                  name="subject"
                >
                  {mainSubjects.map((option: any) => (
                    <MenuItem key={option.code} value={option.code}>
                      {titleCase(option.name)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container className={classes.fields}>
              <Grid item xs={3} className={classes.text}>
                {narrowingBookEnum.AUDIENCE}
              </Grid>
              <Grid item xs={9}>
                <TextField
                  select
                  type="text"
                  className={classes.textField}
                  id={narrowingBookEnum.AUDIENCE}
                  onChange={handleChange}
                  margin="normal"
                  value={values.audience}
                  name="audience"
                >
                  {audiences.map((option: any) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            {errors.title && (
              <Typography
                className={classes.error}
                align="left"
                color="error"
                variant="body2"
                gutterBottom
              >
                {errors.title}
              </Typography>
            )}
          </Box>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item md={3}>
              <Button
                onClick={handleClose}
                variant="outlined"
                className={clsx(classes.button, classes.cancelButton)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item md={3}>
              <Button
                // onClick={() => callback(title)}
                type="submit"
                variant="contained"
                className={clsx(classes.button, classes.approveButton)}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default AdvancedSearchEbook;
