import { UserStatus } from "constants/UserStatus";
import { RegistrationType } from "../constants/RegsitrationType";
import { Registration } from "models/Registration";
import { Action, ActionType } from "../models/actiontype";
import {
  createDefaultOrganizationInfo,
  OrganizationInfo,
} from "models/OrganizationInfo";
import createReducer from "./createReducer";
import { GovernmentId } from "models/OrgInfoDocs";
import { createDefaultUserInfo, UserInfo } from "models/UserInfo";

const initialState = {
  page: "/register/access-code",
  isEmailVerified: false,
  type: RegistrationType.INDIVIDUAL,
  userRoles: [],
  orgInfo: createDefaultOrganizationInfo(),
  userInfo: createDefaultUserInfo(),
  userStatus: UserStatus.PENDING,
};

export const registrationState = createReducer<Registration>(initialState, {
  [ActionType.REG_SET_ACCOUNT_TYPE](
    state: Registration,
    action: Action<string>
  ) {
    return { ...state, type: action.payload };
  },
  [ActionType.REG_SET_ROLETYPE](state: Registration, action: Action<any>) {
    return { ...state, userRoles: action.payload };
  },
  [ActionType.REG_SET_ORG_DETAILS](
    state: Registration,
    action: Action<OrganizationInfo>
  ) {
    return { ...state, orgInfo: action.payload };
  },
  [ActionType.REG_SET_DOC_DETAILS](
    state: Registration,
    action: Action<GovernmentId>
  ) {
    return { ...state, orgInfo: { ...state.orgInfo, orgDocs: action.payload } };
  },
  [ActionType.SET_USER_PROFILE](state: Registration, action: Action<UserInfo>) {
    return { ...state, userInfo: action.payload };
  },
  [ActionType.REG_SET_CURRENT_PAGE](
    state: Registration,
    action: Action<string>
  ) {
    return { ...state, page: action.payload };
  },
  [ActionType.SET_USER_STATUS](
    state: Registration,
    action: Action<UserStatus>
  ) {
    return { ...state, userStatus: action.payload };
  },
  [ActionType.RESET_REG_STATE]() {
    return initialState;
  },
});
