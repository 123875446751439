import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import spinner from "assets/loader/Spinner-1s-200px.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      boxShadow: "none",
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      zIndex: 2000,
    },
    container: {
      width: "100%",
    },
  })
);
export default function FullPageLoader() {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <img src={spinner} alt={"Loading..."} />
    </Grid>
  );
}
