import baseService from "./baseService";

export default class LandingPageService {
  async getCountries() {
    return baseService.get(`/landing/countries`);
  }

  async getCurrencies() {
    return baseService.get(`/landing/currencies`);
  }

  async getLanguages() {
    return baseService.get(`/landing/languages`);
  }

  async getAudiences() {
    return baseService.get(`/landing/audiences`);
  }

  async getSubjects() {
    return baseService.get(`/landing/subjects`);
  }

  async getMainSubjects() {
    return baseService.get(`/landing/main-subjects`);
  }

  async getContributorTypes() {
    return baseService.get(`/landing/contributor-types`);
  }

  async getArtForms() {
    return baseService.get(`/landing/art-forms`);
  }

  async getArtMediums() {
    return baseService.get(`/landing/art-mediums`);
  }
}
