export interface GovernmentId {
  docs: OrgInfoDocs[];
  agreeConditions?: boolean;
}

export interface OrgInfoDocs {
  file: any;
  docName: string;
  docType: string;
  docKey?: string;
  docUrl?: string;
  docFormat?: string;
}

export const createDefaultOrgInfoDocs = () => {
  return {
    file: null,
    docName: "",
    docType: "",
    docUrl: "",
    docFormat: "",
    docKey: "",
  };
};

export const createDefaultGovernmentIdObject = (): GovernmentId => {
  return {
    docs: [createDefaultOrgInfoDocs()],
    agreeConditions: false,
  };
};
