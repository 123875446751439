import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface LandingSearchProps {
  width?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: 51,
      margin: "auto"
    },
    field: {
      padding: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        display: "flex"
      },
      border: "solid 1px rgba(37, 32, 102, 0.3)"
    },
    panel: {
      marginBottom: "10px",
      backgroundColor: "#dee8f1",
      minHeight: "66px"
    },
    heading: {
      paddingTop: theme.spacing(2),
      fontWeight: 600
    },
    details: {
      backgroundColor: "#ffffff",
      display: "flex",
      flexDirection: "column"
    },
    fields: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      position: "relative"
    },

    iconButton: {
      position: "absolute",
      top: 0,
      right: 0,
      marginTop: theme.spacing(1)
    },

    advSearch: {
      paddingTop: 15,
      marginRight: 15,
      float: "right",
      "&:hover": {
        backgroundColor: "transparent"
      }
    },

    boxText: {
      color: "003a99",
      textDecoration: "underline",
      textTransform: "capitalize"
    },

    input: {
      width: "100%",
      paddingTop: 8,
      float: "left"
    },

    text: {
      // position: "absolute",
      marginTop: 25,
      left: 0,
      opacity: "0.65"
    },

    textCard: {
      fontWeight: 600,
      paddingTop: "4px"
    },

    selectTab: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",  /* Centering y-axis */
      alignItems: "center",
      cursor: "pointer"
    },

    paper: {
      marginTop: theme.spacing(3),
      height: "124px",
      border: "solid 1px #cdd4d7",
    },

    img: {
      width: "54.7px",
      height: "36px",
      marginRight: 10,
      alignItems: "center",
    },

    bordered: {
      marginTop: theme.spacing(3),
      height: "124px",
      border: "2px solid #252066",
      borderRadius: 2,
      boxShadow: "0 1px 1px 0 rgba(98, 104, 111, 0.16)",
    },

    textField: {
      width: "95%",
      marginLeft: theme.spacing(2)
    },
    dateField: {
      width: "100%",
      marginLeft: theme.spacing(2)
    },
    dateToField: {
      width: "100%",
      marginLeft: 20
    },
    dateVAField: {
      width: "90%",
      marginLeft: theme.spacing(2)
    },
    container: {
      overflowX: "hidden",
      width: "100%",
      maxWidth: "640px",
      // height: "833px",
      minHeight: "85%",
      backgroundColor: "#ffffff"
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    },
    filterPalceholder: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    button: {
      textTransform: "capitalize",
      padding: theme.spacing(1),
      margin: "16px 24px 16px 24px",
      borderRadius: "0px",
      fontSize: "14px",
      fontWeight: 600,
      float: "right",
      width: "90%",
      "&:disabled": {
        cursor: "not-allowed"
      }
    },
    cancelButton: {

      float: "right",
      width: "90%",
      color: "#202f35",
    },
    approveButton: {
      color: "#fff",
      backgroundColor: "#241f63",
      "&:hover": {
        backgroundColor: "#08062b"
      }
    },
    error: {
      marginTop: theme.spacing(2)
    }

  })
);

export default useStyles;
