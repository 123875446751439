import { ROWS_PER_PAGE_LANDING } from "constants/AppConstants";
import { ClaimType } from "../constants/ClaimType";
export interface LandingSearchResult {
  id: string;
  title: string;
  creator: string;
  createdDate: Date;
  thumbnail: string;
  type: ClaimType;
  claimType: string;
}

export interface Pagination {
  rowsPerPage: number;
  currentPage: number;
}

export interface LandingSearchResultResponse {
  total: number;
  data: LandingSearchResult[];
}
export interface LandingSearchResults {
  searchResults: LandingSearchResultResponse;
  loading: boolean;
  type: string;
  sortBy: string;
  pagination: Pagination;
}

export function createDefaultLandingSearchResult() {
  return {
    searchResults: { data: [], total: 0 },
    loading: false,
    type: "Basic",
    sortBy: "",
    pagination: {
      rowsPerPage: ROWS_PER_PAGE_LANDING,
      currentPage: 0,
    },
  };
}
