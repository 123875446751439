import * as React from "react";
import * as ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { history } from "./configureStore";
import ReduxRoot from "./ReduxRoot";
import config from "./config";

const OnRedirectCallback = (appState: any) => {
  if (
    window.location.search.includes("code=") &&
    !window.location.href.includes(`/oauth`)
  ) {
    history.push("/onboarding");
  } else {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
};
const rootEl = document.getElementById("root");

fetch(
  `${window.location.protocol}//${window.location.host}/configsettings.json`
)
  .then((response) => response.json())
  .then((data) => {
    config.SERVER_HOST = `${data.SERVER_HOST}`;
    config.AUTH0_DOMAIN = `${data.AUTH0_DOMAIN}`;
    config.AUTH0_CLIENT_ID = `${data.AUTH0_CLIENT_ID}`;
    config.AUTH0_AUDIENCE = `${data.AUTH0_AUDIENCE}`;
    config.PLAID_PUBLIC_KEY = `${data.PLAID_PUBLIC_KEY}`;
    config.ETHEREUM_BLOCKCHAIN_EXPLORER_PREFIX =
      data.ETHEREUM_BLOCKCHAIN_EXPLORER_PREFIX;
    config.CREATOR_ACCESS_CODE_HASH = data.CREATOR_ACCESS_CODE_HASH;
    config.ADMIN_ACCESS_CODE_HASH = data.ADMIN_ACCESS_CODE_HASH;
    config.SMARTLOOK_KEY = data.SMARTLOOK_KEY;
    config.NODE_ENV = data.NODE_ENV;
    ReactDOM.render(
      <Auth0Provider
        domain={config.AUTH0_DOMAIN}
        clientId={config.AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        onRedirectCallback={OnRedirectCallback}
        audience={config.AUTH0_AUDIENCE}
        scope="read:current_user"
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <ReduxRoot />
      </Auth0Provider>,
      rootEl
    );
  });

// comment in for PWA with service worker in production mode
// serviceWorker();
