import { Action, ActionType } from "../models/actiontype";
import { Language } from "../models/Language";

import createReducer from "./createReducer";

export const languages = createReducer<Language[]>([], {
  [ActionType.LANGUAGES_ADD](state: Language[], action: Action<Language[]>) {
    return action.payload;
  },
});
