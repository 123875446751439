import { isEmpty, trim } from "lodash";
import { detect } from "detect-browser";
import moment from "moment";
import { RegistrationType } from "constants/RegsitrationType";
import { Roles, ADMINS } from "../constants/Roles";
import { PENDING, APPROVED, VERIFIED, ACTIVE } from "../constants/AppConstants";
import { history } from "configureStore";
import { ClaimType } from "constants/ClaimType";

export function checkIfPending(status: string): Boolean {
  return status.toUpperCase() === PENDING.toUpperCase();
}

export function determineClaimTypeForAdmin(): string {
  if (history.location.pathname.includes("/admin/claim/book")) {
    return ClaimType.BOOK;
  } else {
    return ClaimType.VISUAL_ARTS;
  }
}

export function isRegistrationPage(): boolean {
  return history.location.pathname.includes("/register/");
}

/**
 * Method to verify if Super Admin(Owner) - Can manage other admin accounts
 * @param role
 */
export function isSuperAdmin(role: string | null | undefined): Boolean {
  if (role) {
    return Roles.OWNER.toUpperCase() === role.toUpperCase();
  }
  return false;
}

/**
 * Method to verify if Admin (Normal)
 * @param role
 */
export function isClaimant(role: string | null | undefined): Boolean {
  if (role) {
    return Roles.CLAIMANT.toUpperCase() === role.toUpperCase();
  }
  return false;
}

/**
 * Method to verify if Admin (Normal)
 * @param role
 */
export function isAdmin(role: string | null | undefined): Boolean {
  if (role) {
    return Roles.ADMIN.toUpperCase() === role.toUpperCase();
  }
  return false;
}

/**
 * Method to verify if Admin or Owner(Super admin)
 * @param role
 */
export function hasAdminRole(role: string | null | undefined): Boolean {
  if (role) {
    return ADMINS.includes(role.toUpperCase());
  }
  return false;
}

/**
 * Method to return Verified for Approved status
 * @param status
 */
export function changeToVerified(status: string): string {
  if (status && status.toUpperCase() === APPROVED.toUpperCase()) {
    return VERIFIED;
  }
  return status;
}

type Order = "asc" | "desc";

export function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K
): (
  a: { [key in K]: number | string },
  b: { [key in K]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

export function buildUserDetailsJSON(userData: any) {
  if (userData) {
    switch (userData.type) {
      case RegistrationType.ORGAZANIZATION:
        return buildOrgData(userData);
      case RegistrationType.INDIVIDUAL:
        return buildIndividualData(userData);
      default:
        return [];
    }
  }
}

function buildIndividualData(userData: any) {
  const { contacts, addresses, email } = userData;

  const contactInfo =
    contacts.length > 0
      ? {
          "First name": contacts[0].firstName,
          "Middle name": contacts[0].middleName,
          "Last name": contacts[0].lastName,

          "Date of Birth": contacts[0].dateOfBirth
            ? moment.utc(contacts[0].dateOfBirth).format("YYYY/MM/DD")
            : "-",
          "Date of Death": contacts[0].dateOfDeath
            ? moment.utc(contacts[0].dateOfDeath).format("YYYY/MM/DD")
            : "-",
          Email: contacts[0].emailId,
          "Phone Number": contacts[0].phoneNumber,
          ISNI: contacts[0].isni,
        }
      : {
          "First name": "-",
          "Middle name": "-",
          "Last name": "-",
          "Date of Birth": "-",
          "Date of Death": "-",
          Email: email,
          "Phone Number": "-",
          ISNI: "-",
        };

  const personalInfo = {
    groupTile: "Personal Information",
    typeOfGroup: "stab",
    groupData: [contactInfo],
  };

  const addressInfo = {
    groupTile: "Address Information",
    typeOfGroup: "stab",
    groupData: addresses.map((address: any) => {
      return {
        "Type of Address": address.type,
        Address: address.address,
        City: address.city,
        "Province/State": address.province,
        Country: address.country,
        "Postal/Zip Code": address.postCode,
      };
    }),
  };

  return [personalInfo, addressInfo];
}

function buildOrgData(userData: any) {
  const { orgInfo, contacts, addresses } = userData;
  let orgDocs;
  if (orgInfo) {
    orgDocs = orgInfo.orgDocs;
  }
  const orgTab = {
    groupTile: "Organization Information",
    typeOfGroup: "stab",
    groupData: [
      {
        "Organization Legal Name": orgInfo && orgInfo.organizationName,
        "Website URL": {
          value: orgInfo && orgInfo.websiteUrl,
          link: orgInfo && orgInfo.websiteUrl,
        },
        "Phone Number": orgInfo && orgInfo.phoneNumber,
        // "Address": addresses[0].address,
        "Parent Company": orgInfo && orgInfo.parentCompany,
        "Subsidiary Company": orgInfo && orgInfo.subsidiaryCompany,
      },
    ],
  };

  const addressInfo = {
    groupTile: "Address Information",
    typeOfGroup: "stab",
    groupData: addresses.map((address: any) => {
      return {
        Address: address.address,
        City: address.city,
        "Province/State": address.province,
        Country: address.country,
        "Postal/Zip Code": address.postCode,
      };
    }),
  };

  const contactInfo = {
    groupTile: "Contact person Information",
    typeOfGroup: "stab",
    groupData: contacts.map((contact: any) => {
      return {
        "First Name": contact.firstName,
        "Last Name": contact.lastName,
        Email: contact.email,
        "Phone Number": contact.phoneNumber,
        "Job Title": contact.jobTitle,
        isAuthorized: contact.isAuthorized,
      };
    }),
  };

  const orgDocsInfo = {
    groupTile: "Verification of organization status",
    typeOfGroup: "table",
    groupData: [
      { thead: ["Name", "Documents"] },
      {
        trow:
          orgDocs &&
          orgDocs.map((doc: any) => [
            doc.docName,
            { value: doc.docKey, link: doc.docUrl },
          ]),
      },
    ],
  };

  return [orgTab, addressInfo, contactInfo, orgDocsInfo];
}

export function returnValue(data: any) {
  if (!isEmpty(data)) return data;
  return "-";
}

export const returnLookupValue = (code: any, list: any) => {
  if (isEmpty(code)) return "-";
  const obj = list.filter((item: any) => item.code === code);
  if (isEmpty(obj[0]) || !obj[0].name) return "-";
  return obj[0].name;
};

export const filterEmptyRows = (data: any[]) => {
  return data.filter((rowData) =>
    Object.values(rowData).every((element) => !isEmpty(element))
  );
};

export const unpack = (res: any) => {
  return res.data.data;
};

export const retrieveResponse = (res: any) => {
  return res.data;
};

export const trimAllFieldsInObjectAndChildren = (o: any) => {
  return JSON.parse(JSON.stringify(o).replace(/"\s+|\s+"/g, '"'));
};

export const changeVerifiedToApproved = (status: string) => {
  return status && status.toUpperCase() === VERIFIED.toUpperCase()
    ? APPROVED
    : status;
};

export const changeApprovedToVerified = (status: string) => {
  return status && status.toUpperCase() === APPROVED.toUpperCase()
    ? VERIFIED
    : status;
};

export const changeApprovedToActive = (status: string) => {
  return status && status.toUpperCase() === APPROVED.toUpperCase()
    ? ACTIVE
    : status;
};

export const titleCase = (str: string) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

export const displayName = (firstName: any, middleName: any, lastName: any) => {
  return trim(`${firstName || ""} ${middleName || ""} ${lastName || ""}`);
};

export const digestMessage = async (message: string) => {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-1", msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join(""); // convert bytes to hex string
  return hashHex;
};

export const isSafari =
  /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

export const isValidDate = (dateString: string) => {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false; // Invalid format
  var d = new Date(dateString);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
};

export function isValidEmail(email: string): boolean {
  // eslint-disable-next-line
  const regex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  if (!email || email.length > 256) {
    return false;
  }

  const resultValid = regex.test(email);
  if (!resultValid) {
    return false;
  }

  const parts = email.split("@");
  if (parts[0].length > 64) {
    return false;
  }
  const domainParts = parts[1].split(".");
  if (
    domainParts.some((part) => {
      return part.length > 64;
    })
  ) {
    return false;
  }

  return true;
}

export function isNewVAClaimPage(): boolean {
  return history.location.pathname === "/user/claim/claim-type/add-va";
}

export function isNewBookClaimPage(): boolean {
  return (
    history.location.pathname === "/user/claim/claim-type/add-books/manual"
  );
}

export function stripHTMLTags(value: string): string {
  if (!value) return "";
  return value.replace(
    /(<([^>]+)>)|&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6});/gi,
    ""
  );
}

export function fileExists(imageUrl: string) {
  try {
    const http = new XMLHttpRequest();
    http.open("HEAD", imageUrl, false);
    http.send();
    return http.status === 200;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function checkImage(imageSrc: string) {
  const img = new Image();
  img.src = imageSrc;
  const noImage = await img.onerror;
  return noImage;
}

export function isSupportedBrowser() {
  const browser = detect();
  return (
    browser &&
    (browser.name === "chrome" ||
      browser.name === "safari" ||
      browser.name === "ios" ||
      browser.name === "ios-webview")
  );
}

// Checks for ISBN-10 or ISBN-13 format
// Reference: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s13.html
export function checkISBN(subject: string) {
  // Pass it if there is no value
  if (!subject) return true;
  var regex = /^(?:ISBN(?:-1[03])?:? )?(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)(?:97[89][- ]?)?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$/;

  if (regex.test(subject)) {
    // Remove non ISBN digits, then split into an array
    var chars = subject.replace(/[- ]|^ISBN(?:-1[03])?:?/g, "").split("");
    // Remove the final ISBN digit from `chars`, and assign it to `last`
    var last = chars.pop();
    var sum = 0;
    var check, i;

    if (chars.length === 9) {
      // Compute the ISBN-10 check digit
      chars.reverse();
      for (i = 0; i < chars.length; i++) {
        sum += (i + 2) * parseInt(chars[i], 10);
      }
      check = 11 - (sum % 11);
      if (check === 10) {
        check = "X";
      } else if (check === 11) {
        check = "0";
      }
    } else {
      // Compute the ISBN-13 check digit
      for (i = 0; i < chars.length; i++) {
        sum += ((i % 2) * 2 + 1) * parseInt(chars[i], 10);
      }
      check = 10 - (sum % 10);
      if (check === 10) {
        check = "0";
      }
    }

    if (check == last) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
