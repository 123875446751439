import { LandingSearchResultResponse } from "./../models/LandingSearchResult";
import { retrieveResponse } from "./../utils/utility";
import { stringify } from "query-string";
import LandingPageService from "services/landingPageService";
import ClaimService from "services/claimService";
import { Action, ActionType } from "models/actiontype";
import { toast } from "react-toastify";

/**
 * Basic search on the basis of title
 * @param data
 */
export function getSearchResults(data: any) {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    let res: any;
    try {
      let req = new ClaimService();
      const query = stringify(data, { sort: false });
      dispatch(setSearchResultsLoading(true));
      res = await req.getAllClaims(query);
      //dispatch the response
      dispatch(setSearchResults(retrieveResponse(res)));
      dispatch(setSearchResultsLoading(false));
    } catch (error) {
      console.log(error);
      toast.error("Failed to load the search results");
      return null;
    }
  };
}

export function setSearchResults(
  res: LandingSearchResultResponse
): Action<LandingSearchResultResponse> {
  return {
    type: ActionType.SET_SEARCH_RESULTS,
    payload: res,
  };
}

export function setSearchResultsLoading(loading: boolean) {
  return {
    type: ActionType.SET_SEARCH_RESULTS_LOADING,
    payload: loading,
  };
}

/**
 * For fetching countries
 */
export function fetchCountries() {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    let res: any;
    try {
      let req = new LandingPageService();
      res = await req.getCountries();

      dispatch({
        type: ActionType.COUNTRIES_ADD,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch countries");
      return null;
    }
  };
}

/**
 * For fetching currencies
 */
export function fetchCurrencies() {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    let res: any;
    try {
      let req = new LandingPageService();
      res = await req.getCurrencies();

      dispatch({
        type: ActionType.CURRENCIES_ADD,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch currencies");
      return null;
    }
  };
}

/**
 * For fetching languages
 */
export function fetchLanguages() {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    let res: any;
    try {
      let req = new LandingPageService();
      res = await req.getLanguages();
      dispatch({
        type: ActionType.LANGUAGES_ADD,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch languages");
      return null;
    }
  };
}

/**
 * For fetching audiences
 */
export function fetchAudiences() {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    let res: any;
    try {
      let req = new LandingPageService();
      res = await req.getAudiences();
      dispatch({
        type: ActionType.AUDIENCES_ADD,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch audiences");
      return null;
    }
  };
}

/**
 * For fetching subjects
 */
export function fetchSubjects() {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    let res: any;
    try {
      let req = new LandingPageService();
      res = await req.getSubjects();
      dispatch({
        type: ActionType.SUBJECTS_ADD,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch subjects");
      return null;
    }
  };
}

/**
 * For fetching main subjects
 */
export function fetchMainSubjects() {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    let res: any;
    try {
      let req = new LandingPageService();
      res = await req.getMainSubjects();
      dispatch({
        type: ActionType.MAIN_SUBJECTS_ADD,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch subjects");
      return null;
    }
  };
}

/**
 * For fetching contributor types
 */
export function fetchContributorTypes() {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    let res: any;
    try {
      let req = new LandingPageService();
      res = await req.getContributorTypes();
      dispatch({
        type: ActionType.CONTRIBUTORTYPES_ADD,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch contributor types");
      return null;
    }
  };
}

/**
 * For fetching art forms
 */
export function fetchArtForms() {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    let res: any;
    try {
      let req = new LandingPageService();
      res = await req.getArtForms();
      dispatch({
        type: ActionType.ARTFORMS_ADD,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch art forms");
      return null;
    }
  };
}

/**
 * For fetching art medium
 */
export function fetchArtMediums() {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    let res: any;
    try {
      let req = new LandingPageService();
      res = await req.getArtMediums();
      dispatch({
        type: ActionType.ARTMEDIUMS_ADD,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch art mediums");
      return null;
    }
  };
}
