import { Action, ActionType } from "../models/actiontype";
import { Country } from "../models/Country";

import createReducer from "./createReducer";

export const countries = createReducer<Country[]>([], {
  [ActionType.COUNTRIES_ADD](state: Country[], action: Action<Country[]>) {
    return action.payload;
  },
});
