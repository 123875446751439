import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import * as React from "react";
import { CssBaseline } from "@material-ui/core";

// Creating a theme with custom primary and secondar colors, can update and maintain this when we receive this from design.
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#ffffff",
      main: "#241f63",
      dark: "#3333ff",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffffff",
      main: "#3333ff",
      dark: "#a90000",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Open Sans",
  },

  overrides: {
    // desginers use steppers that scale the whole page, need to do an over right on the theme.
    MuiMobileStepper: {
      root: {
        // background: "#fffff"
      },
      progress: {
        width: "100%",
        color: "#a90000",
      },
    },

    MuiButton: {
      root: {
        minWidth: "0px",
        textTransform: "capitalize",
        borderRadius: "2px",
      },
    },

    MuiFormLabel: {
      asterisk: {
        color: "#d0021b",
      },
    },
  },
});

function withRoot(Component: any) {
  function WithRoot(props: object) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
