import axiosService from "./axiosService";
import config from "../config";

const baseService = {
  apiUrlVersion: `/api/v2`,
  get(endpoint: string, token?: any) {
    return token
      ? axiosService.get(
          `${config.SERVER_HOST}${this.apiUrlVersion}${endpoint}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
      : axiosService.get(
          `${config.SERVER_HOST}${this.apiUrlVersion}${endpoint}`
        );
  },
  post(endpoint: string, data: any, token: string) {
    return axiosService.post(
      `${config.SERVER_HOST}${this.apiUrlVersion}${endpoint}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  put(endpoint: string, data: any, token: string) {
    return axiosService.put(
      `${config.SERVER_HOST}${this.apiUrlVersion}${endpoint}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  patch(endpoint: string, data: any, token: string) {
    return axiosService.patch(
      `${config.SERVER_HOST}${this.apiUrlVersion}${endpoint}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  delete(endpoint: string, token: string) {
    return axiosService.delete(
      `${config.SERVER_HOST}${this.apiUrlVersion}${endpoint}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  async uploadFile(
    file: File,
    uploadType: string,
    privateFile: boolean,
    token: string
  ) {
    let priv = "";
    if (privateFile) {
      priv = "?private=true";
    }

    let { type } = file;
    if (!type) {
      type = file.name.includes(`.mobi`)
        ? `application/x-mobipocket-ebook`
        : "null";
    }

    const toCall = `${config.SERVER_HOST}${
      this.apiUrlVersion
    }/upload/${"none"}/${uploadType}/${encodeURIComponent(
      type
    )}/${encodeURIComponent(file.name)}${priv}`;

    const urls = await axiosService
      .get(toCall, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return { url: "", key: "" };
      });

    await axiosService.put(urls.url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    return {
      filename: file.name,
      format: type,
      previewUrl: urls.previewUrl,
      key: urls.key
    };
  },
};

export default baseService;
