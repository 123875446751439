export enum MetaDataBookEnum {
  KEYWORD = "Keyword",
  ISBN = "ISBN",
  TITLE = "Title",
  CONTRIBUTOR = "Contributor",
  PUBLISHER = "Publisher",
}

export enum MetaDataVisualArtsEnum {
  KEYWORD = "Keyword",
  TITLE = "Title",
  CREATOR = "Creator",
  ADDITIONAL_CONTRBUTOR = "Additional Contributor",
}

export enum NarrowingMetaBookEnum {
  DATE_PUBLISHED = "Date Published",
  DATE_START = "Date Start",
  DATE_END = "Date End",
  STATUS = "Status",
  SUBJECT = "Subject",
  AUDIENCE = "Audience",
}

export enum NarrowingMetaVisualArtsEnum {
  COPYRIGHT_YEAR = "Copyright Year",
  STATUS = "Status",
  MEDIA = "Media",
  SURFACE = "Surface",
  ART_TYPE = "Type of Art",
}
