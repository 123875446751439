import { Action, ActionType } from "../models/actiontype";
import { ClaimState } from "../models/claimstate";

import createReducer from "./createReducer";

export const claimstate = createReducer<ClaimState[]>([], {
  [ActionType.CLAIMSTATE_ADD](
    state: ClaimState[],
    action: Action<ClaimState[]>
  ) {
    return action.payload;
  },
  [ActionType.CLAIMSTATE_RESET](state: [], action: Action<ClaimState>) {
    return [...state.splice(state.length)];
  },
});
