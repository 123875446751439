import { Action, ActionType } from "../models/actiontype";
import { ArtMedium } from "../models/ArtMedium";

import createReducer from "./createReducer";

export const artMediums = createReducer<ArtMedium[]>([], {
  [ActionType.ARTMEDIUMS_ADD](state: ArtMedium[], action: Action<ArtMedium[]>) {
    return action.payload;
  },
});
