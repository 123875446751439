import React, { useState } from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";
import book from "../../../assets/book.svg";
import LandingSearchDialogCss from "../../../styles/LandingPage/LandingSearchDialogCss";
import visualArt from "../../../assets/combined-shape.svg";
import AdvancedSearchEbook from "./AdvancedSearchEbook";
import AdvancedSearchVA from "./AdvancedSearchVA";

interface LandingSearchDialogProps {
  open: boolean;
  closeDialog: () => void;
  clearField: () => void;
}

export default function LandingSearchDialog({ open, closeDialog, clearField }: LandingSearchDialogProps) {
  const classes = LandingSearchDialogCss();
  const [dialog, setDialog] = React.useState(open);
  const [selected, setSelected] = useState({
    isBook: true,
    isVA: false,
  });

  function handleClose() {
    setDialog(false);
    closeDialog();
  }

  return (
    <Dialog
      id="advance-dialog"
      open={dialog}
      onClose={handleClose}
      maxWidth="lg"
      PaperProps={{
        style: {
          margin: '20px',
          maxHeight: '95%'
        }
      }}
    >
      <div className={classes.container}>
        <Box ml={2} mr={2} mb={2} >
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h6" className={classes.heading}>
                Advanced Search
              </Typography>
            </Grid>
            <IconButton
              className={classes.iconButton}
              aria-label="menu"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container>
            <Grid item xs={6} style={{paddingRight: '10px'}}>
              <Paper
                className={clsx(
                  classes.selectTab,
                  selected.isBook ? classes.bordered : classes.paper
                )}
                onClick={() => setSelected({ isBook: true, isVA: false })}
              >
                <img src={book} className={classes.img} alt={"book_img"} />
                <Typography className={classes.textCard} variant="body1">
                  Books
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} style={{paddingLeft: '10px'}}>
              <Paper
                className={clsx(
                  classes.selectTab,
                  selected.isVA ? classes.bordered : classes.paper
                )}
                onClick={() => setSelected({ isBook: false, isVA: true })}
              >
                <img
                  src={visualArt}
                  className={classes.img}
                  alt={"visualart_img"}
                />
                <Typography className={classes.textCard} variant="body1">
                  Visual Arts
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Box fontSize={14}>
          {selected.isBook ? (
            <AdvancedSearchEbook
              handleClose={handleClose}
              clearField={clearField}
            />
          ) : (
              <AdvancedSearchVA
                handleClose={handleClose}
                clearField={clearField}
              />
            )}
        </Box>
      </div>
    </Dialog>
  );
}
