import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import access from "../../../assets/access.png";
import va from "../../../assets/va.png";
import carfac from "../../../assets/carfac.png";
import blockleaders from "../../../assets/blockleaders.jpg";
import globe_and_mail from "../../../assets/globe_and_mail .png";
import Publishers_Weekly_logo from "../../../assets/Publishers_Weekly_logo.png";
import publishing_perspectives from "../../../assets/publishing_perspectives.png";
import footer from "../../../assets/home-footer.svg";
import HomeFooter from "./HomeFooter";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "#252066",
      backgroundColor: "#fff",
      textAlign: "center",
      backgroundImage: `url(${footer})`,
      backgroundPosition: "center bottom",
      backgroundRepeat: "no-repeat",
    },

    container: {
      margin: "auto",
    },

    logo: {
      maxWidth: 250,
      verticalAlign: "middle",
      padding: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        maxWidth: 175,
      },
    },

    frame: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(5),
        height: "inherit",
      },
      marginTop: "20px",
      marginBottom: "40px",
    },

    helper: {
      display: "inline-block",
      height: "100%",
      verticalAlign: "middle",
    },
  })
);

export default function LandingPageFooter() {
  const classes = useStyles();
  return (
    <Typography component="div" className={classes.root}>
      <Box
        pt={5}
        fontSize={31}
        fontWeight={600}
        lineHeight={1.71}
        letterSpacing={1.29}
        textAlign="center"
      >
        Our Partners
      </Box>

      <Grid container className={classes.frame}>
        <Grid item xs={6} md={4}>
          <span className={classes.helper}></span>
          <a
            href="https://accesscopyright.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={access} alt="access" className={classes.logo} />
          </a>
        </Grid>
        <Grid item xs={6} md={4}>
          <span className={classes.helper}></span>
          <a
            href="https://www.cova-daav.ca/en/ "
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={va} alt="va" className={classes.logo} />
          </a>
        </Grid>
        <Grid item xs={6} md={4}>
          <span className={classes.helper}></span>
          <a
            href="https://www.carfac.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={carfac} alt="carfac" className={classes.logo} />
          </a>
        </Grid>
      </Grid>

      <Box
        pt={5}
        fontSize={31}
        fontWeight={600}
        lineHeight={1.71}
        letterSpacing={1.29}
        textAlign="center"
      >
        Featured In
      </Box>
      <Grid container className={classes.frame}>
        <Grid item xs={6} md={3}>
          <span className={classes.helper}></span>
          <a
            href="https://www.publishersweekly.com/pw/print/20190930/81314-global-pubs-turn-to-blockchain-and-crowdfunding.html"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={Publishers_Weekly_logo}
              alt="Publishers_Weekly_logo"
              className={classes.logo}
            />
          </a>
        </Grid>
        <Grid item xs={6} md={3}>
          <span className={classes.helper}></span>
          <a
            href="https://www.accesscopyright.ca/media/1372/new-canadian-blockchain-registry-aims-to-help-artists-protect-their-work-in-digital-world.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={globe_and_mail}
              alt="globe_and_mail"
              className={classes.logo}
            />
          </a>
        </Grid>
        <Grid item xs={6} md={3}>
          <span className={classes.helper}></span>
          <a
            href="https://publishingperspectives.com/2019/01/blockchain-publishing-proof-of-concept-canada-access-copyright-spain-renodo/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={publishing_perspectives}
              alt="publishing_perspectives"
              className={classes.logo}
            />
          </a>
        </Grid>
        <Grid item xs={6} md={3}>
          <span className={classes.helper}></span>
          <a
            href="https://blockleaders.io/tag/roanie-levy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={blockleaders}
              alt="blockleaders"
              className={classes.logo}
            />
          </a>
        </Grid>
      </Grid>
      <HomeFooter />
    </Typography>
  );
}
