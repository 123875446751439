import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { RouteComponentProps } from "react-router";
import queryString from "query-string";
import FullPageLoader from "components/common/FullPageLoader";
import { history } from 'configureStore';
import { useActions } from 'actions';
import * as UserActions from 'actions/user';

interface Props extends RouteComponentProps<void> { };

export default function OAuthPage(props: Props) {
    const { isAuthenticated, loginWithRedirect, user, getAccessTokenSilently } = useAuth0();
    const { location } = props;
    const { code, oauthState, oauthRedirectUri } = queryString.parse(location.search);
    const userActions = useActions(UserActions);

    if (oauthState) {
        localStorage.setItem("oauth_object", JSON.stringify({ state: oauthState, redirectUri: oauthRedirectUri }));
    }

    if (!isAuthenticated && !code) {
        loginWithRedirect({ redirectUri: `${window.location.origin}/oauth`});
    }

    useEffect(() => {
        const authUser = async () => {
            if (user) {
                const data = await userActions.pollAuth0UserStatus(getAccessTokenSilently);
                if (data.emailVerified) {
                    const token = await getAccessTokenSilently();
                    const stringifiedOAuthObject = localStorage.getItem(`oauth_object`) || `{}`;
                    const oauthObject = JSON.parse(stringifiedOAuthObject);
                    localStorage.removeItem(`oauth_object`);
                    window.location.assign(`${oauthObject.redirectUri}?code=${token}&state=${oauthObject.state}`);
                } else {
                    history.push('/user/register/verify-email?oauth=true');
                }
            }
        };
        authUser();
    }, [user]);

    return <FullPageLoader />;
}
