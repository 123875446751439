import { stringify } from "querystring";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import { Action, ActionType } from "models/actiontype";
import { RegistrationType } from "constants/RegsitrationType";
import UserService from "services/userService";
import { history } from "configureStore";
import * as authActions from "./auth";
import {
  CONFLICT_ERROR,
  NOT_FOUND_ERROR_STATUSCODE,
  USER_ID,
} from "constants/AppConstants";
import { unpack } from "utils/utility";
import { ClaimRegistrantsData } from "models/ClaimRegistrantsDetails";
import { AdminUsers } from "models/AdminUsers";

export function createAdminUser(
  data: any,
  getAccessToken: (arg0: object) => Promise<string>
) {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    try {
      let req = new UserService();
      const response = await req.updateUser(data, getAccessToken);
      const result = unpack(response);
      dispatch(
        authActions.setUser({
          id: result.id,
          email: result.emailId,
          role: result.role,
          type: result.type,
          status: result.userStatus,
          isEmailVerified: result.emailVerified,
          firstName: isEmpty(result.contacts)
            ? ""
            : result.contacts[0].firstName,
          lastName: isEmpty(result.contacts) ? "" : result.contacts[0].lastName,
        })
      );
      return result;
      // history.push('/admin/dashboard');
    } catch (error) {
      console.log(error);
      console.log(error.response);
      //If Conflict also -> then navigate to /admin/dashboard
      if (error.statusCode === CONFLICT_ERROR) {
        history.push("/admin/dashboard");
      }
      return null;
    }
  };
}

export function getAllUsers(
  data: any,
  getAccessToken: (arg0: object) => Promise<string>
) {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    try {
      let req = new UserService();
      //form the query string here
      const queryParam = stringify(data);
      const response = await req.getAllUsers(queryParam, getAccessToken);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };
}

export function setRegistrationType(type: RegistrationType): Action<any> {
  return {
    type: ActionType.REG_SET_ACCOUNT_TYPE,
    payload: type,
  };
}

export function getUser(
  getAccessToken: (arg0: object) => Promise<string>,
  id?: string
) {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    try {
      const req = new UserService();
      // when ID empty, request current user
      const userRequestedID = id ? id : "";
      const response = await req.getUser(userRequestedID, getAccessToken);
      const data = unpack(response);
      dispatch(setRegistrationType(data.type));
      // Update current app user data if another user isn't requested
      if (!id) {
        dispatch(
          authActions.setUser({
            id: data.id,
            email: data.emailId,
            role: data.role,
            type: data.type,
            status: data.userStatus,
            requestType: data.requestType,
            isEmailVerified: data.emailVerified,
            firstName: isEmpty(data.contacts) ? "" : data.contacts[0].firstName,
            lastName: isEmpty(data.contacts) ? "" : data.contacts[0].lastName,
            contactId: isEmpty(data.contacts) ? "" : data.contacts[0].id,
          })
        );
      }
      return data;
    } catch (error) {
      console.log(error);
      console.log(error.response);
      if (
        error.response &&
        error.response.status === NOT_FOUND_ERROR_STATUSCODE
      ) {
        return null;
      }
    }
  };
}

export function getAllUserCounts(
  getAccessToken: (arg0: object) => Promise<string>
) {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    try {
      let req = new UserService();
      const response = await req.getAllUsersCount(getAccessToken);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };
}

export function pollAuth0UserStatus(
  getAccessToken: (arg0: object) => Promise<string>
) {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    try {
      const req = new UserService();
      const response = await req.checkEmailVerification(getAccessToken);
      return response.data.data;
    } catch (error) {
      console.log(error);
      toast.error(`Failed to verify email due to ${error}`);
      return error;
    }
  };
}

export function resendVerifyEmail(
  getAccessToken: (arg0: object) => Promise<string>
) {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    try {
      let req = new UserService();
      return await req.resendVerifyEmail(getAccessToken);
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export function setClaimRegistrant(
  cr: ClaimRegistrantsData
): Action<ClaimRegistrantsData> {
  return {
    type: ActionType.SET_CLAIM_REGISTRANT,
    payload: cr,
  };
}

export function setAdminUsers(data: AdminUsers[]) {
  return {
    type: ActionType.LOAD_ADMINS,
    payload: data,
  };
}

export function resetAdminUsers() {
  return {
    type: ActionType.RESET_ADMINS,
  };
}

export function getClaimRegistrant(
  userId: string,
  getAccessToken: (arg0: object) => Promise<string>
) {
  return async (dispatch: { (arg0: any): void; (arg0: any): void }) => {
    try {
      const req = new UserService();
      const response = await req.getUser(userId, getAccessToken);
      const cr = unpack(response);
      const crData = {
        id: cr.id,
        name: isEmpty(cr.orgInfo)
          ? isEmpty(cr.contacts)
            ? ""
            : cr.contacts[0].fullName
          : cr.orgInfo.organizationName,
        type: cr.type,
        email: cr.email,
        phoneNumber: isEmpty(cr.orgInfo)
          ? isEmpty(cr.contacts)
            ? ""
            : cr.contacts[0].phoneNumber
          : cr.orgInfo.phoneNumber,
        parentCompany: isEmpty(cr.orgInfo) ? "" : cr.orgInfo.parentCompany,
        subsidiaryCompany: isEmpty(cr.orgInfo)
          ? ""
          : cr.orgInfo.subsidiaryCompany.toString(),
        websiteUrl: isEmpty(cr.orgInfo) ? "" : cr.orgInfo.websiteUrl,
        address: isEmpty(cr.addresses)
          ? ""
          : cr.addresses[0].address || cr.addresses[0].city
          ? `${cr.addresses[0].address}, ${cr.addresses[0].city}, ${cr.addresses[0].province}, ${cr.addresses[0].country}, ${cr.addresses[0].postCode}`
          : "",
      };
      dispatch(setClaimRegistrant(crData));
      return crData;
    } catch (error) {
      console.log(error);
      toast.error(`Failed to load claim registrant ${userId}`);
      return null;
    }
  };
}
