import {
  LandingSearchResultResponse
} from "./../models/LandingSearchResult";
import {
  LandingSearchResults,
  createDefaultLandingSearchResult,
} from "models/LandingSearchResult";
import createReducer from "./createReducer";
import { Action, ActionType } from "models/actiontype";

const initialState = createDefaultLandingSearchResult();

export const landingSearchState = createReducer<LandingSearchResults>(
  initialState,
  {
    [ActionType.SET_SEARCH_RESULTS](
      state: LandingSearchResults,
      action: Action<LandingSearchResultResponse>
    ) {
      return { ...state, searchResults: action.payload };
    },
    [ActionType.SET_SEARCH_RESULTS_LOADING](
      state: LandingSearchResults,
      action: Action<boolean>
    ) {
      return { ...state, loading: action.payload };
    },
    [ActionType.SET_SEARCH_CLAIM_TYPE](
      state: LandingSearchResults,
      action: Action<string>
    ) {
      return { ...state, type: action.payload };
    },
  }
);
